import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    CardContent,
    Typography,
    IconButton,
    TextFieldProps, CircularProgress,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { toggleDailyReport } from '../mapbox/mapboxSlice'
import {
    CardDailyReportHeaderInfoModalStyled,
    CardDailyReportInfoModalStyled,
    DailyReportInfoModalDialogButton,
    DailyReportInfoModalDialogButtonsWrapper,
    DailyReportInfoModalFormControlStyled,
    DailyReportInfoModalTitle,
    DailyReportInfoModalClockWrapper,
    DailyReportInfoModalClockDivider,
    DailyReportInfoModalDescription,
    DailyReportInfoModalDescriptionWrapper,
    ClockInputDailyReportRenderWrapper,
    ClockDateDailyReportInput,
    DailyReportInfoModalLoaderWrapper,
    DailyReportInfoModalListStation,
} from './dailyReportInfoModalStyles'
import { useSelector } from 'react-redux'
import {
    selectConfig,
    selectEpoch,
    selectNowTime,
    selectTimeZone
} from '../core/coreSlice'
import {
    loadDailyReport,
    restoreInitialReportInfo,
    selectDailyReportInfo,
    selectFetchingReportInfo,
} from './store/dailyReportSlice'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import {
    loadMonthAvailableData,
    selectAvailableData, selectMaxAndMinTimesWithData,
} from '../../containers/dashboardContainer/DashboardContainerSlice'
import { NoDataMessage } from '../../containers/manageContainer/components/kpi/kpisComparison/kpiComparisonDialog/KpiComparisonDialogStyles'
import { htmlToPrint } from './dailyReportToPrint'

export const DailyReportInfoModal: React.FC<DailyReportInfoModalProps> = () => {
    const { t, i18n } = useTranslation()
    const _config: IModuleConfig = useAppSelector(selectConfig)
    const _timeZone: string = useSelector(selectTimeZone)
    const _epoch: number = useAppSelector(selectEpoch)
    const _nowTime = useAppSelector(selectNowTime)
    const _fetchingDailyReport = useAppSelector(selectFetchingReportInfo)
    const _dailyReport = useAppSelector(selectDailyReportInfo)
    const _availableData = useAppSelector(selectAvailableData)
    const _availableMaxAndMinTimesWithData = useAppSelector(selectMaxAndMinTimesWithData)
    const [clockDate, setClockDate] = useState<DateTime>(DateTime.fromMillis(_epoch, { zone: _timeZone }))
    const [maxDateTime, setMaxDateTime] = useState(DateTime.fromMillis(_nowTime, { zone: _timeZone }))
    const [availableDatesAndTimes, setAvailableDatesAndTimes] = useState<AvailableDatesAndTimes>({})
    const [openCalendar, setOpenCalendar] = useState<boolean>(true)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const nextDate: DateTime = DateTime.fromMillis(_nowTime, { zone: _timeZone })
        if (_nowTime !== 0) {
            setMaxDateTime(nextDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_nowTime, _timeZone])

    useEffect(() => {
        if (_availableData.length > 0) {
            const _availableDatesAndTimes: any = {}
            _availableData.forEach((t: number) => {
                const currentTime = DateTime.fromMillis(t, { zone: _timeZone })
                const date = currentTime.toISODate() || ''
                if (!currentTime.hasSame( DateTime.fromMillis(_nowTime, { zone: _timeZone }), 'day')) {
                    const hour = currentTime.get('hour')
                    const minute = currentTime.get('minute')
                    if (!_availableDatesAndTimes[date]) {
                        _availableDatesAndTimes[date] = {}
                    }
                    if (!_availableDatesAndTimes[date][hour]) {
                        _availableDatesAndTimes[date][hour] = []
                    }
                    _availableDatesAndTimes[date][hour].push(minute)
                }
            })
            setAvailableDatesAndTimes(_availableDatesAndTimes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_availableData])

    useEffect(() => {
        dispatch(
            loadMonthAvailableData({
                month: clockDate.month,
                year: clockDate.year,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_availableMaxAndMinTimesWithData])

    useEffect(() => {
        const dateReport = DateTime.fromMillis(_epoch, { zone: _timeZone })
        if (!dateReport.hasSame( DateTime.fromMillis(_nowTime, { zone: _timeZone }), 'day')) {
            dispatch(loadDailyReport(dateReport.toFormat('dd-LL-yyyy')))
            setOpenCalendar(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleClose = () => {
        dispatch(toggleDailyReport(false))
        dispatch(restoreInitialReportInfo())
    }

    const handleSelectEvent = () => {
        let divContent = document.getElementById("infoToPrint")?.innerHTML
        let printWindow = window.open('', '', 'height=700, width=900')
        printWindow?.document.open()
        printWindow?.document.write(
            htmlToPrint(t, divContent, clockDate.setZone(_timeZone).toFormat(_config.date_format.date))
        )
        printWindow?.document.close()
    }

    const handleAccept = (date: DateTime) => {
        setOpenCalendar(false)
        setClockDate(date)
        const dateReport = date.toFormat('dd-LL-yyyy')
        dispatch(loadDailyReport(dateReport))
    }

    const handleClockChange = (date: DateTime) => {
        if (date) {
            const maxAvailableDate = DateTime.fromMillis(Number(_availableMaxAndMinTimesWithData.to), {
                zone: _timeZone,
            })
            const minAvailableDate = DateTime.fromMillis(Number(_availableMaxAndMinTimesWithData.from), {
                zone: _timeZone,
            })
            if (date > maxAvailableDate) {
                setClockDate(maxAvailableDate)
            } else if (date < minAvailableDate) {
                setClockDate(minAvailableDate)
            } else {
                setClockDate(date)
            }
        }
    }

    const renderClockInput = (params: TextFieldProps) => {
        let dateParams = { ...params }

        if (dateParams?.inputProps?.value) {
            dateParams.inputProps = {
                ...params.inputProps,
                value: clockDate.setZone(_timeZone).toFormat(_config.date_format.date),
            }
        }

        return (
            <ClockInputDailyReportRenderWrapper>
                <ClockDateDailyReportInput
                    {...dateParams}
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    onClick={() => setOpenCalendar(true)}
                />
            </ClockInputDailyReportRenderWrapper>
        )
    }

    const prepareDailyReport = _dailyReport ? _dailyReport.split('<br>').map((text: string, index: number) => {
        if (index  === _dailyReport.split('<br>').length - 1){
            return text.split(/[:,]/)
        } else {
            return text
        }
    }) : null

    return (
        <CardDailyReportInfoModalStyled>
            <CardDailyReportHeaderInfoModalStyled
                action={
                    <IconButton aria-label='settings' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={<DailyReportInfoModalTitle>{t('dailyReport.dailyReport')}</DailyReportInfoModalTitle>}
            />
            <CardContent >
                <DailyReportInfoModalFormControlStyled key='event-log-form' variant='standard'>
                    <DailyReportInfoModalClockWrapper>
                        <LocalizationProvider
                            localeText={{
                                cancelButtonLabel: t('clockButtons.cancel'),
                                okButtonLabel: t('clockButtons.ok'),
                            }}
                            dateAdapter={AdapterLuxon}
                            adapterLocale={i18n.language}>
                            <MobileDatePicker
                                value={clockDate}
                                open={openCalendar}
                                showToolbar={false}
                                onAccept={(date: any) => handleAccept(date)}
                                onChange={date => date && handleClockChange(date)}
                                onClose={() => {
                                    if (clockDate) {
                                        dispatch(
                                            loadMonthAvailableData({
                                                month: clockDate.month,
                                                year: clockDate.year,
                                            })
                                        )
                                    }
                                    setOpenCalendar(false)
                                }}
                                onOpen={() => setOpenCalendar(true)}
                                DialogProps={{ style: { color: 'magenta' } }}
                                renderInput={(params: any) => renderClockInput(params)}
                                onMonthChange={(date: DateTime) => {
                                    dispatch(loadMonthAvailableData({ month: date.month, year: date.year }))
                                }}
                                shouldDisableDate={(day: DateTime) => {
                                    const date: string = day.toISODate() || ''
                                    const result = availableDatesAndTimes[date]
                                    return !result
                                }}
                                maxDate={maxDateTime}
                            />
                        </LocalizationProvider>
                    </DailyReportInfoModalClockWrapper>
                    <DailyReportInfoModalClockDivider />
                    <DailyReportInfoModalDescriptionWrapper id={'infoToPrint'}>
                        {_fetchingDailyReport ? (
                            <DailyReportInfoModalLoaderWrapper>
                                <CircularProgress />
                            </DailyReportInfoModalLoaderWrapper>
                        ): (
                            prepareDailyReport ? prepareDailyReport.map((text: any, index: number) => {
                                if (index === prepareDailyReport.length -1) {
                                    return text.map((li:string, i: number) => {
                                        if (i === 0){
                                            return <DailyReportInfoModalDescription  key={'description-report' + index} variant='body1'>{`${li}:`}</DailyReportInfoModalDescription>
                                        } else {
                                            return <DailyReportInfoModalListStation key={'description-report-li' + i} >{li}</DailyReportInfoModalListStation>
                                        }
                                    })
                                } else {
                                    return <DailyReportInfoModalDescription key={'description-report' + index} variant='body1'>{text}</DailyReportInfoModalDescription>
                                }
                            }) : (
                                <NoDataMessage align='center'>
                                    {t('dailyReport.noReportPrint')}
                                </NoDataMessage>
                            )
                        )}
                    </DailyReportInfoModalDescriptionWrapper>
                </DailyReportInfoModalFormControlStyled>
                <DailyReportInfoModalDialogButtonsWrapper>
                    <DailyReportInfoModalDialogButton onClick={handleClose} variant='text'>
                        <Typography variant='button' color='primary'>
                            {t('buttons.cancel')}
                        </Typography>
                    </DailyReportInfoModalDialogButton>
                    <DailyReportInfoModalDialogButton onClick={handleSelectEvent} variant='contained' disabled={!prepareDailyReport}>
                        <Typography variant='button'>
                            {t('buttons.print')}
                        </Typography>
                    </DailyReportInfoModalDialogButton>
                </DailyReportInfoModalDialogButtonsWrapper>
            </CardContent>
        </CardDailyReportInfoModalStyled>
    )
}
