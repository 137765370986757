import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import {
    CardContent,
    Typography,
    IconButton,
    Radio
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { toggleListEvents } from '../mapbox/mapboxSlice'
import {
    CardEventsHeaderInfoModalStyled,
    CardEventsInfoModalStyled,
    EventsInfoModalDialogButton,
    EventsInfoModalDialogButtonsWrapper,
    EventsInfoModalFormControlStyled,
    EventsInfoModalLabelStyled,
    EventsInfoModalTitle, EventsInfoModalTitleRange,
} from './listEventsInfoModalStyles'
import { useSelector } from 'react-redux'
import { selectConfig, selectEpoch, selectTimeZone, storeEpoch } from '../core/coreSlice'
import { selectEventLogList, selectEventLogRange } from './store/listEventsSlice'
import { NoDataMessage } from '../../containers/manageContainer/components/kpi/kpisComparison/kpiComparisonDialog/KpiComparisonDialogStyles'

export const ListEventsInfoModal: React.FC<ListEventsInfoModalProps> = () => {
    const { t } = useTranslation()
    const _timeZone: string = useSelector(selectTimeZone)
    const _epoch: number = useAppSelector(selectEpoch)
    const _moduleConfig = useAppSelector(selectConfig)
    const _eventsLogList = useAppSelector(selectEventLogList)
    const _eventsLogRange = useAppSelector(selectEventLogRange)
    const [eventSelected, setEventSelected] = useState<number>(-1)
    const [indexTOScroll, setIndexTOScroll] = useState<number>(-1)
    const dispatch = useAppDispatch()
    const refToScroll = useRef(document.createElement("label"))

    useEffect( () => {
        if(_eventsLogList.length > 0) {
            let indexToScroll = _eventsLogList
                .slice()
                .sort((a, b) => b - a)
                .findIndex((epochEvent: number) => epochEvent === _epoch)
            if(indexToScroll === _eventsLogList.length - 1) {
                indexToScroll = _eventsLogList.length - 2
            }
            setIndexTOScroll(indexToScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_eventsLogList])

    const handleClose = () => {
        dispatch(toggleListEvents(false))
    }

    const handleChange = (id: number) => {
        setEventSelected(id)
    }

    const handleSelectEvent = () => {
        dispatch(storeEpoch(eventSelected))
        dispatch(toggleListEvents(false))
    }

    const fromDate = DateTime.fromMillis(_eventsLogRange.from, { zone: _timeZone }).toFormat(_moduleConfig.date_format.dateTime)
    const toDate = DateTime.fromMillis(_eventsLogRange.to, { zone: _timeZone }).toFormat(_moduleConfig.date_format.dateTime)

    const cardTitle = (
        <>
            <EventsInfoModalTitle>{t('listEvents.detectedEvents')}</EventsInfoModalTitle>
            <EventsInfoModalTitleRange >
                {`${t('listEvents.from')}: ${fromDate} - ${t('listEvents.to')}: ${toDate}`}
            </EventsInfoModalTitleRange>
        </>
    )

    if( refToScroll.current &&  eventSelected === -1) {
        refToScroll.current.scrollIntoView()
    }

    return (
        <CardEventsInfoModalStyled>
            <CardEventsHeaderInfoModalStyled
                action={
                    <IconButton aria-label='settings' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={cardTitle}
            />
            <CardContent>
                <EventsInfoModalFormControlStyled key='event-log-form' variant='standard'>
                    {_eventsLogList.length > 0 ?
                        _eventsLogList
                            .slice()
                            .sort((a, b) => b - a)
                            .filter((epochEvent: number) => epochEvent !== _epoch)
                            .map((epochEvent: number,index) => {
                                const date = DateTime.fromMillis(epochEvent, { zone: _timeZone }).toFormat(_moduleConfig.date_format.dateTime)

                                return (
                                    <EventsInfoModalLabelStyled
                                        control={
                                            <Radio
                                                checked={epochEvent === eventSelected}
                                                onChange={() => handleChange(epochEvent)}
                                                value={epochEvent}
                                                color='primary'
                                            />
                                        }
                                        label={date}
                                        key={'event' + index}
                                        ref={index === indexTOScroll ? refToScroll : null}
                                    />
                                )
                            }
                        ) : (
                            <NoDataMessage align='center'>
                                {t('listEvents.noEventsDetected')}
                            </NoDataMessage>
                        )
                    }
                </EventsInfoModalFormControlStyled>
                <EventsInfoModalDialogButtonsWrapper>
                    <EventsInfoModalDialogButton onClick={handleClose} variant='text'>
                        <Typography variant='button' color='primary'>
                            {t('buttons.cancel')}
                        </Typography>
                    </EventsInfoModalDialogButton>
                    <EventsInfoModalDialogButton onClick={handleSelectEvent} variant='contained' disabled={eventSelected === -1}>
                        <Typography variant='button'>
                            {t('buttons.load')}
                        </Typography>
                    </EventsInfoModalDialogButton>
                </EventsInfoModalDialogButtonsWrapper>
            </CardContent>
        </CardEventsInfoModalStyled>
    )
}
