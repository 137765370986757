import React, { useState, useEffect } from 'react'
import {
    selectAnalyticalDataUpdates,
    selectConfig,
    selectEpoch,
    selectNowTime,
    selectRealDataUpdates,
    selectSimulatedDataUpdates,
    selectStatus,
    setStatus,
    storeModuleName,
    updateNowTime,
} from '../../features/core/coreSlice'
import { loadPattern } from '../../features/pattern/store/patternSlice'
import { enableNetworkAssets, loadIncidents } from '../../features/mapbox/mapboxSlice'
import { ToolbarContainer } from '../../components/toolbarComponent/toolbarComponentStyles'
import { ContainerWrapper } from '../../components/containerWrapper/containerWrapper'
import { changeHorizon } from '../../features/horizonSlider/horizonSilderSlice'
import { getMarks } from '../../helpers/SliderMarksHelper'
import HorizonSlider from '../../features/horizonSlider/horizonSlider'
import {
    loadMapData,
    loadMapDataByViewMode,
    loadUpdateAnalyticalData,
    loadUpdateRealData,
    loadUpdateSimulatedData,
} from '../../features/core/mapData/mapDataSlice'
import MapboxContainer from '../../features/mapbox/mapboxContainer'
import { getAllViewMode } from '../../helpers/ContainerHelper'
import Navigator from '../../features/navigator/Navigator'
import { useDispatch } from 'react-redux'
import Pattern from '../../features/pattern/pattern'
import Sidebar from '../../features/sidebar/sidebar'
import { StyledDivider } from '../../components'
import { useAppSelector } from '../../app/hooks'
import Clock from '../../features/clock/clock'
import Views from '../../features/views/views'
import Logo from '../../features/logo/Logo'

const MonitorContainer: React.FC<IContainerProps> = ({ options }: IContainerProps) => {
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _appStatus: string = useAppSelector(selectStatus)
    const _nowTime: number = useAppSelector(selectNowTime)
    const _epoch: number = useAppSelector(selectEpoch)
    const _analyticalDataUpdates: boolean = useAppSelector(selectAnalyticalDataUpdates)
    const _simulatedDataUpdates: boolean = useAppSelector(selectSimulatedDataUpdates)
    const _realDataUpdates: boolean = useAppSelector(selectRealDataUpdates)
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)

    const dispatch = useDispatch()

    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon)
    const containerName = 'monitor'
    const allViewModes = getAllViewMode(containerName, _moduleConfig.view_mode, _moduleConfig.modules)

    useEffect(() => {
        setStatus('loading')
        dispatch(enableNetworkAssets({ networks: 'all', mapHorizon: 'default' }))
        dispatch(updateNowTime())
        dispatch(storeModuleName(containerName))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_appStatus === 'ready' && _nowTime !== 0) {
            dispatch(changeHorizon(0))
            dispatch(loadIncidents({ type: 'all', epoch: _nowTime }))
            dispatch(loadPattern(_nowTime))
            if (!_realDataUpdates && _nowTime === _epoch) {
                dispatch(loadMapData({ epoch: _nowTime, allViewMode: allViewModes }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_appStatus, _nowTime, _epoch])

    useEffect(() => {
        if (_realDataUpdates) {
            dispatch(loadUpdateRealData({ epoch: _nowTime, allViewMode: allViewModes }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_nowTime])

    useEffect(() => {
        if (_analyticalDataUpdates) {
            dispatch(loadUpdateAnalyticalData({ epoch: _nowTime, allViewMode: allViewModes }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_analyticalDataUpdates])

    useEffect(() => {
        if (_simulatedDataUpdates) {
            dispatch(loadUpdateSimulatedData({ epoch: _nowTime, allViewMode: allViewModes }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_simulatedDataUpdates])

    const handleChangeHorizonAndDate = (horizon: number) => {
        dispatch(changeHorizon(horizon))
    }

    const toggleSidebar = (open: boolean): void => {
        setSidebarOpen(!open)
    }

    const handleChangeViewMode = (epoch: number, viewModeId: number, mapId: number) => {
        dispatch(
            loadMapDataByViewMode({ epoch, allViewMode: allViewModes, viewModeId: viewModeId, mapId: mapId, qm: false })
        )
    }

    return (
        <ContainerWrapper>
            <ToolbarContainer>
                <Logo mode={sidebarOpen ? '' : 'compact'} />
                <Navigator config={_moduleConfig} />
                <StyledDivider orientation='vertical' />
                <Clock
                    showArrowProgress={false}
                    isDisabled={true}
                    updateDataWithNowTime={true}
                    disabledNoAvailableDate={false}
                />
                <StyledDivider orientation='vertical' />
                <HorizonSlider
                    isDisabled={false}
                    horizonValue={0}
                    marks={marks}
                    horizonStep={15}
                    changeHorizonAndDate={handleChangeHorizonAndDate}
                />
                <StyledDivider orientation='vertical' />
                <Pattern showName={false} />
                <StyledDivider orientation='vertical' />
                <Views />
            </ToolbarContainer>
            <Sidebar
                open={sidebarOpen}
                toggleSidebar={toggleSidebar}
                options={options}
                epoch={_nowTime}
                containerName={containerName}
            />
            <MapboxContainer
                layers={{
                    network: ['detectors', 'motorways', 'suburban', 'urban', 'local'],
                    incidents: ['real', 'external', 'detected', 'test'],
                    actions: [],
                }}
                sidebarOpen={sidebarOpen}
                containerName={containerName}
                changeViewMode={handleChangeViewMode}
            />
        </ContainerWrapper>
    )
}

export default MonitorContainer
