import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const eventsLogListInitialState: IDailyReportState = {
    fetchingReportInfo: false,
    reportInfo: '',
}

const dailyReportSlice = createSlice({
    name: 'dailyReportInfo',
    initialState: eventsLogListInitialState,
    reducers: {
        storeReportInfo: (state, action: PayloadAction<any>): void => {
            state.reportInfo = action.payload
        },
        restoreInitialReportInfo: (state, action: PayloadAction): void => {
            state.fetchingReportInfo = false
            state.reportInfo = ''
        },
        setFetchingReportInfo: (state, action: PayloadAction<any>): void => {
            state.fetchingReportInfo = false
        },
        loadDailyReport: (state, action: PayloadAction<any>): void => {
            state.fetchingReportInfo = true
        }
    },
})

export const {
    storeReportInfo,
    restoreInitialReportInfo,
    setFetchingReportInfo,
    loadDailyReport
} = dailyReportSlice.actions

export const selectFetchingReportInfo = (state: RootState) => state.dailyReportInfo.fetchingReportInfo
export const selectDailyReportInfo = (state: RootState) => state.dailyReportInfo.reportInfo

export default dailyReportSlice.reducer
