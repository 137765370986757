import { all, AllEffect, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'

import {
    restoreHoverResponsePlanAction,
    selectHoverResponsePlanAction,
    selectSelectedResponsePlanActions,
    storeHoverResponsePlanAction,
    storeSelectedResponsePlanActions,
} from './responsePlanListSelectorSlice'
import { selectTrafficActions, storeActions } from '../../../../features/mapbox/mapboxSlice'

function* markResponsePlanActionsAndEventsAsSelected(): any {
    const _trafficActions = yield select(selectTrafficActions)
    const _selectedActions = yield select(selectSelectedResponsePlanActions)
    const _hoverAction = yield select(selectHoverResponsePlanAction)

    const sectionsUsed: SectionIdByType = {}
    if (_selectedActions) {
        for (const action of _selectedActions.features) {
            if (!sectionsUsed[action.properties!.actions_type]) {
                sectionsUsed[action.properties!.actions_type] = []
            }
            sectionsUsed[action.properties!.actions_type].push(action.properties!.section_id)
        }
    }

    const selectedActions = _selectedActions?.features.map((feature: any) => feature.properties!.id) || []

    const actionHasSectionInUse = (actionId: number) => {
        const action = _trafficActions.features.find((action: any) => action.properties.id === actionId)
        if (
            action &&
            sectionsUsed[action.properties.actions_type] &&
            sectionsUsed[action.properties.actions_type].includes(action.properties.section_id)
        ) {
            return !selectedActions.includes(actionId)
        }
        return false
    }

    const actionsFeatures = _trafficActions.features.map((action: any) => {
        let selected: boolean = false
        if (_selectedActions) {
            selected = Boolean(
                _selectedActions.features.find((feature: any) => feature.properties.id === action.properties.id)
            )
        }
        const disabled = actionHasSectionInUse(action.properties.id) && !selected
        const hover: boolean = _hoverAction === action.properties.id && !disabled
        return {
            ...action,
            properties: {
                ...action.properties,
                selected,
                hover,
                disabled,
            },
        }
    })
    yield put(
        storeActions({
            ..._trafficActions,
            features: actionsFeatures,
        })
    )
}

function* responsePlanListSelectorSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(storeSelectedResponsePlanActions, markResponsePlanActionsAndEventsAsSelected)])
    yield all([yield takeLatest(storeHoverResponsePlanAction, markResponsePlanActionsAndEventsAsSelected)])
    yield all([yield takeLatest(restoreHoverResponsePlanAction, markResponsePlanActionsAndEventsAsSelected)])
}

export default responsePlanListSelectorSaga
