import { FeatureCollection, GeoJsonProperties, LineString } from 'geojson'
import { CircleLayer, FillLayer, LineLayer, SymbolLayer } from 'react-map-gl'
import { EXISTING_CONGESTION, GREY, MUSTARD, NO_DATA, OLIVE_ISH, SUBPATH_NODES } from '../theme'
import { LinePaint } from 'mapbox-gl'

export const getAnchorTooltip = (
    point: { x: number; y: number },
    viewport: { width: number; height: number }
): string => {
    if (point.y > viewport.height * 0.6) {
        if (point.x > (viewport.width * 4) / 5) {
            return 'bottom-right'
        } else if (point.x < viewport.width / 5) {
            return 'bottom-left'
        } else {
            return 'bottom'
        }
    } else {
        if (point.x > (viewport.width * 4) / 5) {
            return 'top-right'
        } else if (point.x < viewport.width / 5) {
            return 'top-left'
        } else {
            return 'top'
        }
    }
}

export const getColorStyles = (viewMode: IViewMode): any => {
    let colorStyles: any = [
        'step',
        ['feature-state', viewMode.variable],
        NO_DATA,
        -200,
        EXISTING_CONGESTION,
        -199,
        NO_DATA,
    ]

    const styles: IViewModeStyles = viewMode.styles

    styles.ranges.main.forEach(range => {
        colorStyles.push(range.min)
        colorStyles.push(range.color)
    })
    return colorStyles
}

export const getBorderDetectorStyles = (viewMode: IViewMode): any => {
    let colorStyles: any = ['step', ['feature-state', 'reliability'], NO_DATA, -1, NO_DATA]

    const styles: IViewModeStyles = viewMode.styles

    styles.ranges.reliability?.forEach(range => {
        colorStyles.push(range.min)
        colorStyles.push(range.color)
    })
    return colorStyles
}

export const detectorsLayerInitialState: CircleLayer = {
    id: 'aimsunLive_detectors',
    source: 'aimsunLive_detectors_source',
    'source-layer': 'geogkdetector',
    type: 'circle',
    paint: {
        'circle-color': OLIVE_ISH,
    },
}

export const diversionRouteSource = {
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: [{
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'MultiLineString',
                coordinates: []
            }
        }]
    }
}

export const diversionRouteLayer = {
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
    paint: {
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 2, 14, 2, 15, 2, 16, 6, 17, 10, 18, 14,
        ],
        'line-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 1, 14, 1, 15, 1, 16, 0.8, 17, 0.7, 18, 0.5,
        ]
    }
}

export const motorwaysLayerInitialState: LineLayer = {
    id: 'aimsunLive_motorways',
    source: 'aimsunLive_motorways_source',
    'source-layer': 'geogksection',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': OLIVE_ISH,
        'line-opacity': 0.1,
    },
}

export const centroidLayerInitialState: FillLayer = {
    id: 'aimsunLive_centroid',
    source: 'aimsunLive_centroid_source',
    'source-layer': 'geogkcentroid',
    type: 'fill',
    paint: {
        'fill-color': '#000000',
        'fill-outline-color': '#000000',
        'fill-opacity': 0.1,
    },
}

export const centroidLayerSelectedInitialState: FillLayer = {
    id: 'aimsunLive_centroid_selected',
    source: 'aimsunLive_centroid_source',
    'source-layer': 'geogkcentroid',
    type: 'fill',
    paint: {
        'fill-color': '#000000',
        'fill-outline-color': '#000000',
        'fill-opacity': 0.1,
    },
    filter: ['in', 'id', ''],
}

export const centroidClickLayerSelectedInitialState: FillLayer = {
    id: 'aimsunLive_centroid_click_selected',
    source: 'aimsunLive_centroid_source',
    'source-layer': 'geogkcentroid',
    type: 'fill',
    paint: {
        'fill-color': '#000000',
        'fill-outline-color': '#000000',
        'fill-opacity': 0.1,
    },
    filter: ['in', 'id', ''],
}

export const centroidEditedInitialState: FillLayer = {
    id: 'aimsunLive_centroid_edited',
    source: 'aimsunLive_centroid_source',
    'source-layer': 'geogkcentroid',
    type: 'fill',
    paint: {
        'fill-color': '#000000',
        'fill-outline-color': '#000000',
        'fill-opacity': 0.1,
    },
    filter: ['in', 'id', ''],
}

export const lineFromConnectCentroidState: LineLayer = {
    id: 'aimsunLive_centroid_line_from_connect',
    type: 'line',
    source: 'aimsunLive_centroid_line_from_connect_source',
    paint: {
        'line-width': 2,
        'line-color': '#d31515',
    },
}

export const lineToConnectCentroidState: LineLayer = {
    id: 'aimsunLive_centroid_line_to_connect',
    type: 'line',
    source: 'aimsunLive_centroid_line_to_connect_source',
    paint: {
        'line-width': 2,
        'line-color': '#38d315',
    },
}

export const nodesLayerInitialState: CircleLayer = {
    id: 'aimsunLive_nodes',
    source: 'aimsunLive_nodes_source',
    'source-layer': 'geogknode',
    type: 'circle',
    paint: {
        'circle-color': GREY,
        'circle-radius': 1,
        'circle-opacity': 0.1,
        'circle-color-transition': { duration: 1000 },
        'circle-radius-transition': { duration: 1000 },
        'circle-opacity-transition': { duration: 1000 },
    },
}

export const subpathsLayerInitialState: LineLayer = {
    id: 'aimsunLive_subpaths',
    source: 'aimsunLive_subpath_source',
    'source-layer': 'geogksubpath',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': GREY,
        'line-width': 0.1,
        'line-opacity': 0.1,
    },
}

export const initialFromLineData: FeatureCollection<LineString, GeoJsonProperties> = {
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [],
            },
            properties: {},
        },
    ],
    type: 'FeatureCollection',
}

export const initialToLineData: FeatureCollection<LineString, GeoJsonProperties> = {
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [],
            },
            properties: {},
        },
    ],
    type: 'FeatureCollection',
}

export const suburbanLayerInitialState: LineLayer = {
    id: 'aimsunLive_suburban',
    source: 'aimsunLive_suburban_source',
    'source-layer': 'geogksection',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': OLIVE_ISH,
        'line-opacity': 0,
    },
}

export const urban1LayerInitialState: LineLayer = {
    id: 'aimsunLive_urban_1',
    source: 'aimsunLive_urban_1_source',
    'source-layer': 'geogksection',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': OLIVE_ISH,
        'line-opacity': 0,
    },
}

export const urban2LayerInitialState: LineLayer = {
    id: 'aimsunLive_urban_2',
    source: 'aimsunLive_urban_2_source',
    'source-layer': 'geogksection',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': OLIVE_ISH,
        'line-opacity': 0,
    },
}

export const localLayerInitialState: LineLayer = {
    id: 'aimsunLive_local',
    source: 'aimsunLive_local_source',
    'source-layer': 'geogksection',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': OLIVE_ISH,
        'line-opacity': 0,
    },
}

export const publicTransportLayerInitialState: LineLayer = {
    id: 'aimsunLive_public_transport',
    source: 'aimsunLive_public_transport_source',
    'source-layer': 'geogksection',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': OLIVE_ISH,
    },
    filter: ['match', 'id', '', true, false],
}

export const publicTransportHighlightLayerInitialState: LineLayer = {
    id: 'aimsunLive_public_transport_highlight',
    source: 'aimsunLive_public_transport_source',
    'source-layer': 'geogksection',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': MUSTARD,
        'line-width': ['interpolate', ['linear'], ['zoom'], 12, 2, 14, 2, 15, 2, 16, 6, 17, 10, 18, 14],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 12, 1, 14, 1, 15, 1, 16, 0.8, 17, 0.7, 18, 0.5],
    },
    filter: ['match', 'id', '', true, false],
}

export const externalIncidentsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_external',
    source: 'aimsunLive_external_source',
    type: 'symbol',
}

export const detectedIncidentsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_detected',
    source: 'aimsunLive_detected_source',
    type: 'symbol',
}

export const realIncidentsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_real',
    source: 'aimsunLive_real_source',
    type: 'symbol',
}

export const testIncidentsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_test',
    source: 'aimsunLive_test_source',
    type: 'symbol',
}

export const realEventsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_events_real',
    source: 'aimsunLive_events_real_source',
    type: 'symbol',
}

export const testEventsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_events_test',
    source: 'aimsunLive_events_test_source',
    type: 'symbol',
}

export const riskPredictionIncidentsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_risk_prediction',
    source: 'aimsunLive_risk_prediction_source',
    type: 'symbol',
}

export const speedRecommendationLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_speed_recommendation',
    source: 'aimsunLive_speed_recommendation_source',
    type: 'symbol',
}

export const trafficActionsLayerInitialState: SymbolLayer = {
    id: 'aimsunLive_traffic_actions',
    source: 'aimsunLive_traffic_actions_source',
    type: 'symbol',
}

export const getFoundLineData = (
    sections: { id: number; position: number[] }[],
    initialData: any,
    centroidPoint: number[]
) => {
    const coordinateData: any = []
    sections.forEach((section: { id: number; position: number[] }) => {
        coordinateData.push([section.position[0], section.position[1]])
        coordinateData.push(centroidPoint)
    })
    const foundFromData = { ...initialData }
    foundFromData.features[0].geometry.coordinates = coordinateData
    return foundFromData
}

export const getRoadTypeName = (code: string, speedDialNames: { [name: string]: string } | null ) => {
    const roadTypes: any = {
        '1': 'motorway',
        '2': 'suburban',
        '3': 'urban',
        '4': 'urban',
        '5': 'local',
    }
    const roadTypesSpedDial: any = {
        '1': 'motorways',
        '2': 'suburban',
        '3': 'urban',
        '4': 'urban',
        '5': 'local',
    }

    return speedDialNames ? speedDialNames[roadTypesSpedDial[code]] : roadTypes[code] || 'not found'
}

export const getColorByValue: (viewMode: IViewMode | undefined, value: number) => string = (
    viewMode: IViewMode | undefined,
    value: number
): string => {
    if (value !== -1) {
        if (viewMode?.variable === 'congestion_impact' && value === -200) {
            return EXISTING_CONGESTION
        }
        const styles: IViewModeStyles | undefined = viewMode?.styles
        if (styles) {
            const length = styles.ranges.main.length
            const range = styles.ranges.main.find((range: IRange, index: number) => {
                if (index === length - 1) {
                    return value >= range.min
                }
                return range.max ? value >= range.min && value < range.max : value >= range.min
            })
            if (range) {
                return value || value === 0 ? range.color : NO_DATA
            }
        }
    }
    return NO_DATA
}

export const getColorByReliability: (viewMode: IViewMode | undefined, value: number) => string = (
    viewMode: IViewMode | undefined,
    value: number
): string => {
    if (value !== -1) {
        const styles: IViewModeStyles | undefined = viewMode?.styles
        if (styles && styles.ranges.reliability !== undefined) {
            const lenght = styles.ranges.reliability.length
            const range = styles.ranges.reliability.find((range: IRange, index: number) => {
                if (index === lenght - 1) {
                    return value >= range.min
                }
                return range.max ? value >= range.min && value < range.max : value >= range.min
            })
            if (range) {
                return value || value === 0 ? range.color : NO_DATA
            }
        }
    }
    return NO_DATA
}

export const getMapboxViewport = (
    height: number,
    width: number,
    views: number,
    index: number
): { height: number; width: number } => {
    if (views === 4) {
        return { height: height / 2, width: width / 2 }
    }
    if (views === 3 && index === 0) {
        return { height: height / 2, width: width }
    }
    if (views === 3 && index > 0) {
        return { height: height / 2, width: width / 2 }
    }
    if (views === 2) {
        return { height: height / 2, width: width }
    }
    return { height: height, width: width }
}

export const paintLineLayerToOriginal = (): LinePaint => {
    return {
        'line-color': SUBPATH_NODES,
        'line-width': ['interpolate', ['linear'], ['zoom'], 12, 1.5, 14, 1.7, 15, 2, 16, 6, 17, 10, 18, 14],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 12, 1, 14, 1, 15, 1, 16, 0.8, 17, 0.7, 18, 0.5],
    }
}

export const createNextTooltipData = (
    feature: any,
    attr: any,
    selectedEvaluation: IEvaluationStatic
): ICentroidEditedTooltipData => {
    return {
        id: feature.properties.id,
        eid: feature.properties.eid,
        idToShow: feature.properties.eid,
        featureType: feature.properties.type,
        anchor: 'top',
        originalData: {
            residential: attr.residential,
            office: attr.office,
            retail: attr.retail,
            industrial: attr.industrial,
            school: attr.school,
            medical: attr.medical,
            hotelrooms: attr.hotelrooms,
            amAttraction: attr.am_baseline_attraction,
            amGeneration: attr.am_baseline_generation,
            pmAttraction: attr.pm_baseline_attraction,
            pmGeneration: attr.pm_baseline_generation,
        },
        modifiedData: {
            newResidential: selectedEvaluation.fields?.residential,
            newOffice: selectedEvaluation.fields?.office,
            newRetail: selectedEvaluation.fields?.retail,
            newIndustrial: selectedEvaluation.fields?.industrial,
            newSchool: selectedEvaluation.fields?.school,
            newMedical: selectedEvaluation.fields?.hospital,
            newHotelrooms: selectedEvaluation.fields?.hotel_rooms,
            newAmAttraction: selectedEvaluation.fields?.am_attraction,
            newAmGeneration: selectedEvaluation.fields?.am_generation,
            newPmAttraction: selectedEvaluation.fields?.pm_attraction,
            newPmGeneration: selectedEvaluation.fields?.pm_generation,
        },
    }
}

export const getTooltipFeatureLandUseExtraData = (
    isBaseline: boolean,
    optionLandUseView: string | undefined,
    id: number,
    allViewModes: IViewMode[],
    allViewModesBaseline: IViewMode[],
    viewMode: IViewMode,
    _evaluationSimulationData: { [p: string]: IEvaluationSimulationByHorizonData[] },
    _selectBaselineData: IBaselineLandUseTypes[],
    _selectedEvaluation: IEvaluationStatic
): ILandUseExtraData[] => {
    let landUseExtraData: any = []
    if (!isBaseline) {
        allViewModes.forEach((vm: IViewMode) => {
            if (vm.variable !== viewMode.variable) {
                let value: number
                if (_selectedEvaluation.id === -1 || _selectedEvaluation.id === -2) {
                    value = -1
                } else {
                    value =
                        _evaluationSimulationData[vm.variable] &&
                        // @ts-ignore
                        _evaluationSimulationData[vm.variable][0][optionLandUseView][id]?.value
                }
                landUseExtraData.push({
                    label: vm.label,
                    value: value != null ? value : -1,
                    unit: vm.units.main,
                })
            }
        })
    } else {
        allViewModesBaseline.forEach((vm: IViewMode) => {
            if (vm.variable !== viewMode.variable) {
                const baselineData = _selectBaselineData.find(
                    (baselineData: IBaselineLandUseTypes) => baselineData.variable === vm.variable
                )

                if (baselineData) {
                    let value = -1
                    // @ts-ignore
                    if (baselineData[optionLandUseView] && Object.keys(baselineData[optionLandUseView]).length > 0) {
                        // @ts-ignore
                        value = baselineData[optionLandUseView][id] ? baselineData[optionLandUseView][id].value : -1
                    }
                    landUseExtraData.push({
                        label: vm.label,
                        value,
                        unit: vm.units.main,
                    })
                }
            }
        })
    }

    return landUseExtraData
}

export const getFeature = (
    featureSelectedId: any,
    foundSelectedProperties: IFoundFeature,
    mapbox: any,
    searchIdentifier: string
): any => {
    let features
    if (foundSelectedProperties.type === 'centroid') {
        features = mapbox.querySourceFeatures('aimsunLive_centroid_source', {
            sourceLayer: 'geogkcentroid',
        })
    } else if (foundSelectedProperties.type === 'detector') {
        features = mapbox.querySourceFeatures('aimsunLive_detectors_source', {
            sourceLayer: 'geogkdetector',
        })
    } else {
        features = mapbox.querySourceFeatures(`aimsunLive_${foundSelectedProperties.type}_source`, {
            sourceLayer: 'geogksection',
        })
    }

    return features.find((feature: any) => {
        const featureId: string = feature.id?.toString() || 'unknown'
        const featureEid: string = feature.properties?.eid || 'unknown'

        if (searchIdentifier === 'eid') {
            return featureSelectedId === featureEid
        } else if (searchIdentifier === 'id') {
            return featureSelectedId === featureId
        } else {
            return featureSelectedId === featureEid || featureSelectedId === featureId
        }
    })
}

export const getIdToShow = (identifier: string, sectionOnClick: boolean, id: number, eid: string): string => {
    let type = identifier
    if (sectionOnClick) type = 'eid'
    switch (type) {
        case 'eid':
            return eid
        case 'id':
            return id.toString()
        default:
            return id.toString()
    }
}
