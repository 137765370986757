import styled from '@emotion/styled'
import { DialogTitle, IconButton } from '@mui/material'
import { NO_DATA, RED_LIVE } from '../../theme'

export const DialogContentStyled = styled(DialogTitle)`
    padding: 28px 0;
    margin: 0 32px;
    position: relative;
`

export const DialogTitleStyled = styled(DialogTitle)`
    padding: 48px 0 28px;
    margin: 0 32px;
    border-bottom: 1px solid ${NO_DATA};
    position: relative;
`

export const DialogTitleIconButton = styled(IconButton)`
    position: absolute;
    right: 0;
    top: 48px;
    color: ${RED_LIVE};
`
