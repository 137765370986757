import React, { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    selectConfig,
    selectEpoch,
    setStatus,
    selectTimeZone,
    storeEpoch,
    selectStatus,
    updateNowTime,
    selectNowTime,
    selectMainConfig,
} from '../../features/core/coreSlice'
import Navigator from '../../features/navigator/Navigator'
import { StyledDivider } from '../../components'
import Clock from '../../features/clock/clock'
import { ToolbarContainer } from '../../components/toolbarComponent/toolbarComponentStyles'
import Sidebar from '../../features/sidebar/sidebar'
import {
    loadIncidents,
    enableNetworkAssets,
    setSelectedMapbox,
    storeMapboxData,
    restoreRpActions,
    restoreIncidents,
    restoreEvents,
    restoreActions,
    toggleCreateTrafficActionNetwork,
    selectTrafficActionsNetworkFlag,
    selectTrafficActions,
} from '../../features/mapbox/mapboxSlice'
import { ContainerWrapper } from '../../components/containerWrapper/containerWrapper'
import EvaluationSelector from '../../features/evaluationsSelector/evaluationsSelector'
import ResponsePlanSelector from '../manageContainer/components/responsePlanSelector/responsePlanSelector'
import KpisComparison from '../manageContainer/components/kpi/kpisComparison/kpiComparison'
import { useSelector } from 'react-redux'
import {
    loadEvaluation,
    loadEvaluations,
    newEvaluationSelectedState,
    selectEvaluations,
    selectFetchingEvaluations,
    selectSelectedEvaluation,
    setSelectedEvaluation,
} from '../../features/evaluationsSelector/store/evaluationsSlice'
import { loadRPKpi } from './components/kpi/store/kpisResponsePlansSlice'
import { changeHorizon, storeHorizon } from '../../features/horizonSlider/horizonSilderSlice'
import { getAllViewMode } from '../../helpers/ContainerHelper'
import Logo from '../../features/logo/Logo'
import Pattern from '../../features/pattern/pattern'
import HorizonSlider from '../../features/horizonSlider/horizonSlider'
import { getMarks } from '../../helpers/SliderMarksHelper'
import { MapboxLanUseWrapper } from '../landuseContainer/LandUseContainerStyles'
import MapboxComponent from '../../features/mapbox/mapboxComponent'
import EventsToolbar from '../../features/eventsToolbar/eventsToolbar'
import {
    loadManageEvaluationDataByView,
    loadManageEvaluationDataByViewMode,
    loadPastManageEvaluationData,
    taskEvaluationManageProgress,
} from '../../features/evaluationsSelector/store/evaluationsManageSlice'
import { loadEvents, selectEventsSelected, setEventsSelected } from '../../features/eventsToolbar/store/eventsSlice'
import { getNumberResponsePlanId } from '../../features/evaluationsSelector/newManageDemoEvaluationDialog/newManageEvaluationDialogHelper'
import { restoreEvaluationSimulationData } from '../../features/core/simulationEvaluationData/evaluationSimulationDataSlice'
import ResponsePlanEvaluationName from '../../features/responsePlanEvaluationName/responsePlanEvaluationName'
import EvaluationsToolbar from '../../features/evaluationsToolbar/evaluationsToolbar'
import ActionsToolbar from '../../features/actionsToolbar/actionsToolbar'
import {
    selectResponsePlanSelected,
    setOpenResponsePlanCreateDialog,
    setResponsePlanCreating,
    setResponsePlanSelected,
} from '../../features/responsePlanToolbar/store/responsePlanSlice'
import {
    selectEvaluationLiveSelected,
    setEvaluationLiveSelected,
    setOpenEvaluationLiveCreateDialog,
} from '../../features/evaluationsToolbar/store/evaluationLiveSlice'
import {
    loadTrafficActions,
    selectActionIsSelected,
    setActionSelected,
} from '../../features/actionsToolbar/store/actionsSlice'
import { limitResponsePlanIds } from './ManageContainerHelper'
import ResponsePlanListSelector from './components/responsePlanListSelector/responsePlanListSelector'
import {
    loadManageLiveResponsePlan,
    selectResponsePlanLives,
} from '../../features/evaluationsSelector/newManageLiveEvaluationDialog/store/responsePlanManageLiveSlice'
import {
    initialSelectedResponsePlanState,
    restoreSelectedResponsePlan,
    storeSelectedResponsePlan,
    storeSelectedResponsePlanActions,
} from './components/responsePlanListSelector/responsePlanListSelectorSlice'

const emptyMapboxData = (mapId: number) => {
    return {
        stationData: [],
        sectionData: [],
        mapId: mapId,
        viewModeId: 1,
        stationNowData: [],
        stationFutureData: [],
        sectionNowData: [],
        sectionFutureData: [],
        riskPrediction: [],
        riskPredictionData: [],
        speedRecommendationData: [],
        speedRecommendation: [],
        qm: false,
    }
}

const ManageLiveContainer: React.FC<IContainerProps> = ({ options }: IContainerProps) => {
    const _epoch: number = useAppSelector(selectEpoch)
    const _nowTime: number = useAppSelector(selectNowTime)
    const _timeZone: string = useSelector(selectTimeZone)
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _mainConfig: IMainConfig = useAppSelector(selectMainConfig)
    const _selectedEvaluation: IEvaluationStatic = useSelector(selectSelectedEvaluation)
    const _evaluations: IEvaluationStatic[] = useSelector(selectEvaluations)
    const _fetchingEvaluations: boolean = useSelector(selectFetchingEvaluations)
    const _appStatus: string = useAppSelector(selectStatus)
    const _eventsIsSelected: boolean = useAppSelector(selectEventsSelected)
    const _actionsIsSelected: boolean = useAppSelector(selectActionIsSelected)
    const _responsePlanLives: IResponsePlan[] = useAppSelector(selectResponsePlanLives)
    const _responsePlanIsSelected: boolean = useAppSelector(selectResponsePlanSelected)
    const _evaluationsIsSelected: boolean = useAppSelector(selectEvaluationLiveSelected)
    const _trafficActions = useAppSelector(selectTrafficActions)
    const _showTrafficActionsNetwork: boolean = useAppSelector(selectTrafficActionsNetworkFlag)
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)
    const [responsePlanEvaluations, setResponsePlanEvaluations] = useState<number[]>([])
    const [newEvaluationIsSelected, setNewEvaluationIsSelected] = useState<boolean>(false)
    const [pastEvaluationSelected, setPastEvaluationSelected] = useState<boolean>(true)
    const [dimensions, setDimensions] = React.useState<{ height: number; width: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const dispatch = useAppDispatch()

    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon, false)
    const containerName = 'manage'
    const allViewModes = getAllViewMode(containerName, _moduleConfig.view_mode, _moduleConfig.modules)
    const clockInterval: number = Number.parseInt(_mainConfig.defaults['clock-interval']) / 60

    useEffect(() => {
        window.addEventListener('resize', handleResize, false)
        setStatus('loading')
        dispatch(enableNetworkAssets({ networks: 'sections', mapHorizon: 'default' }))
        dispatch(updateNowTime())
        dispatch(loadEvaluations())
        dispatch(storeHorizon(15))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_appStatus === 'ready') {
            dispatch(storeMapboxData(emptyMapboxData(0)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_appStatus])

    useEffect(() => {
        if (_epoch !== 0) {
            dispatch(changeHorizon(15))
            getMapDataByEpoch(_epoch)
            if (!_selectedEvaluation.responsePlans) {
                dispatch(loadTrafficActions())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch, _selectedEvaluation])

    useEffect(() => {
        if (_nowTime !== 0 && _selectedEvaluation.id < 0) {
            storeEpoch(_nowTime)
            selectNewEvaluation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_nowTime])

    useEffect(() => {
        if (_selectedEvaluation.responsePlans) {
            dispatch(storeEpoch(_selectedEvaluation.epoch))
            dispatch(restoreIncidents())
            dispatch(restoreEvents())
            if (_showTrafficActionsNetwork) {
                dispatch(toggleCreateTrafficActionNetwork(0))
            }
            setNewEvaluationIsSelected(false)
            getMapDataBySimulationEpoch(_selectedEvaluation.epoch)
            const responsePlansIds = limitResponsePlanIds(getNumberResponsePlanId(_selectedEvaluation.responsePlans))
            setResponsePlanEvaluations(responsePlansIds)
            dispatch(restoreActions())
            dispatch(restoreSelectedResponsePlan())
        } else {
            if (_selectedEvaluation.id === -1) {
                setNewEvaluationIsSelected(false)
                setResponsePlanEvaluations([])
                dispatch(storeMapboxData(emptyMapboxData(0)))
                dispatch(storeMapboxData(emptyMapboxData(1)))
                dispatch(storeEpoch(_nowTime))
            } else if (_selectedEvaluation.id === -2) {
                dispatch(storeEpoch(_nowTime))
            }
            dispatch(loadTrafficActions())
        }

        dispatch(setActionSelected(false))
        dispatch(setEventsSelected(false))
        dispatch(setResponsePlanSelected(false))
        dispatch(setEvaluationLiveSelected(false))
        dispatch(setOpenEvaluationLiveCreateDialog(false))

        regenerateMap()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedEvaluation])

    useEffect(() => {
        if (_epoch !== 0) {
            if (_eventsIsSelected) {
                dispatch(loadEvents({ epoch: _epoch, type: 'all' }))
                setResponsePlanEvaluations([])
                dispatch(setResponsePlanSelected(false))
                dispatch(setEvaluationLiveSelected(false))
                dispatch(setActionSelected(false))
                if (_showTrafficActionsNetwork) {
                    dispatch(toggleCreateTrafficActionNetwork(0))
                }
                dispatch(restoreActions())
                dispatch(restoreSelectedResponsePlan())
            } else {
                dispatch(restoreEvents())
                dispatch(restoreIncidents())
                getIncidents(_epoch)
                dispatch(loadTrafficActions())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_eventsIsSelected])

    useEffect(() => {
        if (_epoch !== 0) {
            if (_actionsIsSelected) {
                setResponsePlanEvaluations([])
                dispatch(setResponsePlanSelected(false))
                dispatch(setEventsSelected(false))
                dispatch(setEvaluationLiveSelected(false))
                dispatch(loadEvents({ epoch: _epoch, type: 'all' }))
                dispatch(restoreSelectedResponsePlan())
            } else {
                dispatch(restoreEvents())
                getIncidents(_epoch)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_actionsIsSelected])

    useEffect(() => {
        if (_epoch !== 0) {
            if (_responsePlanIsSelected) {
                setResponsePlanEvaluations([])
                dispatch(setEventsSelected(false))
                dispatch(setActionSelected(false))
                if (_showTrafficActionsNetwork) {
                    dispatch(toggleCreateTrafficActionNetwork(0))
                }
                dispatch(setEvaluationLiveSelected(false))
                dispatch(loadManageLiveResponsePlan())
            } else {
                getIncidents(_epoch)
                dispatch(loadTrafficActions())
                dispatch(restoreSelectedResponsePlan())
                dispatch(setResponsePlanCreating(false))
                dispatch(setOpenResponsePlanCreateDialog(false))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_responsePlanIsSelected])

    useEffect(() => {
        if (_epoch !== 0) {
            if (_evaluationsIsSelected) {
                setResponsePlanEvaluations([])
                dispatch(setResponsePlanSelected(false))
                dispatch(setEventsSelected(false))
                dispatch(setActionSelected(false))
                if (_showTrafficActionsNetwork) {
                    dispatch(toggleCreateTrafficActionNetwork(0))
                }
                dispatch(restoreActions())
                dispatch(restoreSelectedResponsePlan())
            } else {
                getIncidents(_epoch)
                dispatch(loadTrafficActions())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_evaluationsIsSelected])

    useEffect(() => {
        if (_selectedEvaluation.id === -1) {
            selectNewEvaluation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedEvaluation])

    const getRPKpis = useCallback(() => {
        dispatch(
            loadRPKpi({
                selectedEvaluation: _selectedEvaluation,
                isStaticMode: false, //Live mode
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedEvaluation])

    const handleChangeHorizonAndDate = (horizon: number) => {
        dispatch(changeHorizon(horizon))
    }

    const toggleSidebar = (open: boolean): void => {
        setSidebarOpen(!open)
    }

    const handleMapboxSelected = (id: number) => {
        dispatch(setSelectedMapbox(id))
    }

    const handleChangeKPISResponsePlansSelected = (responsePlans: string[]) => {
        const numberResponsePlan = responsePlans.map(rp => Number(rp))
        let nextValue = numberResponsePlan
        if (numberResponsePlan.length > 4) {
            nextValue.shift()
        } else if (numberResponsePlan.length === 0) {
            nextValue = responsePlanEvaluations
        }

        nextValue = nextValue.sort((a, b) => a - b)
        setResponsePlanEvaluations(nextValue)
        loadResponsePlanEvaluations(nextValue)
    }

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    const getMapDataByEpoch = (epoch: number) => {
        if (_eventsIsSelected) {
            dispatch(loadIncidents({ epoch, type: 'external' }))
            dispatch(loadIncidents({ epoch, type: 'idm' }))
            dispatch(loadEvents({ epoch, type: 'all' }))
        } else {
            getIncidents(epoch)
        }
    }

    const getIncidents = (epoch: number) => {
        dispatch(loadIncidents({ epoch, type: 'all' }))
    }

    const getMapDataBySimulationEpoch = (epoch: number) => {
        dispatch(loadIncidents({ epoch, type: 'all' }))
    }

    const selectNewEvaluation = () => {
        setNewEvaluationIsSelected(true)
        setResponsePlanEvaluations([])
        dispatch(setSelectedEvaluation(newEvaluationSelectedState))
        dispatch(storeMapboxData(emptyMapboxData(0)))
        dispatch(storeMapboxData(emptyMapboxData(1)))
        dispatch(taskEvaluationManageProgress({ evaluation: newEvaluationSelectedState, variable: '' }))
    }

    const handleEvaluationsChange = (event: number | string) => {
        if (event === -2) {
            selectNewEvaluation()
        } else {
            const currentEval = _evaluations.filter(evaluation => evaluation.id === event)[0]
            dispatch(loadEvaluation(currentEval))
            setNewEvaluationIsSelected(false)
            dispatch(storeHorizon(15))
            dispatch(loadPastManageEvaluationData({ evaluation: currentEval, variable: allViewModes[0].variable }))
            dispatch(restoreRpActions())
            dispatch(taskEvaluationManageProgress({ evaluation: currentEval, variable: allViewModes[0].variable }))
            regenerateMap()
        }
        dispatch(restoreEvaluationSimulationData())
        dispatch(setEventsSelected(false))
        dispatch(setResponsePlanSelected(false))
    }

    const regenerateMap = () => {
        setPastEvaluationSelected(false)
        setTimeout(() => {
            setPastEvaluationSelected(true)
        }, 100)
    }

    const loadResponsePlanEvaluations = (views: number[]) => {
        views.forEach((optionView: number, index) => {
            dispatch(
                loadManageEvaluationDataByView({
                    allViewMode: allViewModes,
                    viewModeId: allViewModes[0].id,
                    mapId: index,
                    option: optionView,
                })
            )
        })
    }

    const handleResponsePlanChange = (event: number[]) => {
        console.log('DEBUG Change Response Plan => ' + event)
        let nextValue = event
        if (event.length > 4) {
            nextValue.shift()
        } else if (event.length === 0) {
            nextValue = responsePlanEvaluations
        }

        nextValue = nextValue.sort((a, b) => a - b)
        setResponsePlanEvaluations(nextValue)
        loadResponsePlanEvaluations(nextValue)
    }

    const handleResponsePlanListChange = (rpId: number | string) => {
        if (rpId !== -1) {
            const selectedResponsePlan =
                _responsePlanLives.find(rp => rp.id === rpId) || initialSelectedResponsePlanState
            const selectedActions = selectedResponsePlan
                .actions!.map(action =>
                    _trafficActions.features.find((feature: any) => feature.properties.id === action)
                )
                .filter(action => action)
            dispatch(storeSelectedResponsePlan(selectedResponsePlan))
            dispatch(
                storeSelectedResponsePlanActions({
                    type: 'FeatureCollection',
                    features: selectedActions,
                })
            )
        } else {
            dispatch(restoreSelectedResponsePlan())
        }
    }

    const handleChangeViewMode = (
        epoch: number,
        viewModeId: number,
        mapId: number,
        isBaseline: boolean,
        landUseViewOption: string,
        manageViewOption: number
    ) => {
        dispatch(
            loadManageEvaluationDataByViewMode({
                epoch: _selectedEvaluation.epoch,
                allViewMode: allViewModes,
                viewModeId: viewModeId,
                mapId: mapId,
                option: manageViewOption,
                evaluation: _selectedEvaluation,
            })
        )
    }

    const layers = {
        network: ['motorways', 'suburban', 'urban', 'local'],
        incidents: ['external', 'incidents', 'test', 'real', 'detected'],
        actions: ['traffic_actions'],
    }
    const mapHeight: number = responsePlanEvaluations.length > 2 ? (dimensions.height - 70) / 2 : dimensions.height - 70
    const windowWidth: number = dimensions.width - (sidebarOpen ? 144 : 66)
    const mapWidth: number = responsePlanEvaluations.length > 1 ? windowWidth / 2 : windowWidth

    const viewport = {
        map1: { height: mapHeight, width: mapWidth },
        map2: { height: mapHeight, width: mapWidth },
        map3: { height: mapHeight, width: responsePlanEvaluations.length === 3 ? windowWidth : windowWidth / 2 },
        map4: { height: mapHeight, width: mapWidth },
    }

    const showArrowProgress = _selectedEvaluation.id === -2 || _selectedEvaluation.id === -1
    const isDisabled = _selectedEvaluation.id > 0

    return (
        <ContainerWrapper>
            <ToolbarContainer>
                <Logo mode={sidebarOpen ? '' : 'compact'} key='aimsunLogo' />
                <Navigator config={_moduleConfig} key='router' />
                <StyledDivider orientation='vertical' key='routerDivider' />
                <Clock
                    key='clock'
                    minutesStep={clockInterval}
                    longTimeStep={{ number: 1, unit: 'hours' }}
                    updateDataWithNowTime={true}
                    showArrowProgress={showArrowProgress}
                    isDisabled={isDisabled}
                    allowFuture={true}
                    allowPast={false}
                    disabledNoAvailableDate={false}
                />
                <StyledDivider orientation='vertical' key='clockDivider' />
                <EvaluationSelector
                    key='evaluationsSelector'
                    setEvaluations={handleEvaluationsChange}
                    evaluations={_evaluations}
                    dateFormat={_moduleConfig.date_format.dateTimeLong}
                    disabled={_fetchingEvaluations}
                    timeZone={_timeZone}
                />
                <StyledDivider orientation='vertical' />
                {_selectedEvaluation.id === -2 && (
                    <>
                        <EventsToolbar key='eventsToolbar' />
                        <StyledDivider orientation='vertical' key='actionsToolbar' />
                        <ActionsToolbar />
                        <StyledDivider orientation='vertical' key='responsePlanToolbar' />
                        <ResponsePlanListSelector
                            key='responsePlanListSelector'
                            setResponsePlan={handleResponsePlanListChange}
                        />
                        <StyledDivider orientation='vertical' />
                        <EvaluationsToolbar allViewModes={allViewModes} key='evaluationsToolbar' />
                        <StyledDivider orientation='vertical' />
                    </>
                )}
                {_selectedEvaluation.id > 0 && (
                    <>
                        <ResponsePlanSelector
                            key='responsePlanSelector'
                            responsePlanSelected={responsePlanEvaluations}
                            handleResponsePlansChange={handleResponsePlanChange}
                            evaluation={_selectedEvaluation}
                            disabled={_selectedEvaluation.id === -1 || newEvaluationIsSelected}
                        />
                        <StyledDivider orientation='vertical' key='responsePlanSelectorDivider' />
                    </>
                )}
                {_selectedEvaluation.id > 0 && (
                    <>
                        <HorizonSlider
                            isDisabled={_selectedEvaluation.id === -1}
                            horizonValue={0}
                            marks={marks}
                            horizonStep={15}
                            changeHorizonAndDate={handleChangeHorizonAndDate}
                            key='horizonSlider'
                        />
                        <StyledDivider orientation='vertical' key='horizonSliderDivider' />
                    </>
                )}
                <Pattern showName={false} key='pattern' />
                <StyledDivider orientation='vertical' key='patternDivider' />
                {_selectedEvaluation.id > 0 && (
                    <KpisComparison
                        isDisabled={_selectedEvaluation.id === -1}
                        getRPKpis={getRPKpis}
                        selectedEvaluation={_selectedEvaluation.id.toString()}
                        apmHorizonCount={4}
                        apmHorizonStep={15}
                        responsePlanEvaluations={responsePlanEvaluations}
                        setSelectedResponsePlans={handleChangeKPISResponsePlansSelected}
                        key='kpiComparison'
                    />
                )}
            </ToolbarContainer>
            <Sidebar
                open={sidebarOpen}
                toggleSidebar={toggleSidebar}
                epoch={_epoch}
                options={options}
                containerName={containerName}
            />
            <MapboxLanUseWrapper marginLeft={sidebarOpen ? 144 : 66}>
                {pastEvaluationSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={0}
                        viewport={viewport.map1}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[0]}
                        isBaseline={false}>
                        {responsePlanEvaluations && responsePlanEvaluations.length > 0 && (
                            <ResponsePlanEvaluationName rpName={responsePlanEvaluations[0]} />
                        )}
                    </MapboxComponent>
                )}
                {pastEvaluationSelected && responsePlanEvaluations.length > 1 && !newEvaluationIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={1}
                        viewport={viewport.map2}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[1]}
                        isBaseline={false}>
                        <ResponsePlanEvaluationName rpName={responsePlanEvaluations[1]} />
                    </MapboxComponent>
                )}
                {pastEvaluationSelected && responsePlanEvaluations.length > 2 && !newEvaluationIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={2}
                        viewport={viewport.map3}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[2]}
                        isBaseline={false}>
                        <ResponsePlanEvaluationName rpName={responsePlanEvaluations[2]} />
                    </MapboxComponent>
                )}
                {pastEvaluationSelected && responsePlanEvaluations.length > 3 && !newEvaluationIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={3}
                        viewport={viewport.map4}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[3]}
                        isBaseline={false}>
                        <ResponsePlanEvaluationName rpName={responsePlanEvaluations[3]} />
                    </MapboxComponent>
                )}
            </MapboxLanUseWrapper>
        </ContainerWrapper>
    )
}

export default ManageLiveContainer
