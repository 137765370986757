import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/store'

interface ResponsePlanManageLiveState {
    responsePlanManageLive: IResponsePlan[]
}
const initialResponsePlanManageLiveState: ResponsePlanManageLiveState = {
    responsePlanManageLive: [],
}

const responsePlanManageLiveSlice = createSlice({
    name: 'responsePlanManageLiveSlice',
    initialState: initialResponsePlanManageLiveState,
    reducers: {
        setResponsePlanLives: (state, action: PayloadAction<IResponsePlan[]>): void => {
            state.responsePlanManageLive = action.payload
        },
        loadManageLiveResponsePlan: (state): void => {},
    },
})

export const { loadManageLiveResponsePlan, setResponsePlanLives } = responsePlanManageLiveSlice.actions

export const selectResponsePlanLives = (state: RootState) => state.responsePlanManageLive.responsePlanManageLive

export default responsePlanManageLiveSlice.reducer
