import {
    all,
    AllEffect,
    call,
    ForkEffect,
    put,
    takeEvery,
} from 'redux-saga/effects'
import { loadDailyReport, setFetchingReportInfo, storeReportInfo } from './dailyReportSlice'
import { getDailyReportInfo } from '../../../services/dailyReportInfo/dailyreportInfoService'

function* fetchDailyReport(action: any): any
{
    const reportInfo = yield call(getDailyReportInfo, action.payload)
    yield put(storeReportInfo(reportInfo.report))
    yield put(setFetchingReportInfo(false))
}

function* dailyReportSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield all([yield takeEvery(loadDailyReport, fetchDailyReport)])])
}

export default dailyReportSaga
