import { TFunction } from 'i18next'

export const htmlToPrint = (translate: TFunction, divContent: any, date: string): string => {
    return (`
        <!DOCTYPE html>
        <html  lang="en">
        <head>
            <title>Daily Report</title>
            <style>
                body { font-family: Arial, sans-serif; }
                h1 { color: #333; }
                .wrapper-boddy { margin: 52px}
                .wrapper-boddy p { display: flex}
                .wrapper-boddy li { margin: 4px 0 12px 4px}
            </style>
        </head>
        <body>
            <div class='wrapper-boddy'>
                ${divContent}
            </div>   
        </body>
        </html>
    `
    )
}
