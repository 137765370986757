import React, { useState, useEffect } from 'react'
import { Checkbox, Container, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material'
import { isRpSelected, parseKPIs, sortRp } from '../kpiHelper'
import {
    TableCellContentStyled,
    TableCellHeaderStyled,
    TableCellHeaderTypography,
    TableHeadStyled,
    TableRowContent,
} from '../../../../../components/tableStyles/tabletyles'
import { IKpiComparisonTableProps, IKpiMapped } from './KpiComparisonTableTypes'
import { TableCellContentComparison, TitleWrapper } from './KpiComparisonTableStyles'
import { GREEN } from '../../../../../theme'
import { useTranslation } from 'react-i18next'

export const KpiComparisonTable: React.FC<IKpiComparisonTableProps> = ({
    responsePlansSelected,
    setResponsePlansSelected,
    kpiList,
    unitsSystem,
}) => {
    const { t } = useTranslation()
    const [KPIs, setKPIs] = useState({})
    const [selectedResponsePlans, setSelectedResponsePlans] = useState<string[]>(responsePlansSelected)

    useEffect(() => {
        let kpis = parseKPIs(kpiList, Object.keys(kpiList), unitsSystem)
        setKPIs(kpis)
    }, [kpiList, unitsSystem])

    const RPsNames = Object.keys(kpiList)

    const isSelected = (rp: string) => isRpSelected(rp, selectedResponsePlans)

    const handleSelectResponsePlan = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rpId = event.target.value
        if (isSelected(rpId)) {
            setSelectedResponsePlans(selectedResponsePlans.filter(id => id !== rpId))
            setResponsePlansSelected(selectedResponsePlans.filter(id => id !== rpId))
        } else {
            setSelectedResponsePlans([...selectedResponsePlans, rpId])
            setResponsePlansSelected([...selectedResponsePlans, rpId])
        }
    }

    const renderKpiRows = (KPIs: IKpiMapped) => {
        return Object.entries(KPIs).map(([kpiName, rpsKpi], index) => {

            const prepareValues = Object.values(KPIs[kpiName].kpiRp).map((kpiValue: { horizons: number, value: number }) => {
                return kpiValue ? kpiValue.value / kpiValue.horizons : -Number.MAX_VALUE
            })

            const filteredValues: number[] = prepareValues.filter((n) => n !== -Number.MAX_VALUE)

            let highLightValue = -1
            if (KPIs[kpiName].target === 'low') {
                highLightValue = Math.min(...filteredValues)
            }
            if (KPIs[kpiName].target === 'high') {
                highLightValue = Math.max(...filteredValues)
            }
            return (
                <TableRowContent key={kpiName} className={index % 2 === 0 ? 'table__row--even' : 'table__row--odd'}>
                    <TableCellContentStyled>
                        <TableCellHeaderTypography align='left' sx={{ paddingLeft: '30px' }}>
                            {kpiName}
                        </TableCellHeaderTypography>
                    </TableCellContentStyled>
                    {RPsNames.sort((a, b) => {
                        return sortRp(a, b, selectedResponsePlans)
                    }).map(rpId => {
                        if (rpsKpi.kpiRp[rpId]) {
                            return (
                                <TableCellContentComparison
                                    align='center'
                                    key={rpsKpi.name + rpId}
                                    className={isSelected(rpId) ? '' : 'no-selected'}>
                                    <Typography
                                        align='center'
                                        color={rpsKpi.kpiRp[rpId].value / rpsKpi.kpiRp[rpId].horizons === highLightValue
                                            && highLightValue !== -1 ? GREEN : 'inherit'}
                                    >
                                        {
                                            rpsKpi.kpiRp[rpId].value / rpsKpi.kpiRp[rpId].horizons !== -1 ?
                                                (rpsKpi.kpiRp[rpId].value / rpsKpi.kpiRp[rpId].horizons).toFixed(2)
                                            : t('kpi.NA')
                                        }
                                    </Typography>
                                </TableCellContentComparison>
                            )
                        } else {
                            return (
                                <TableCellContentComparison
                                    align='center'
                                    key={rpsKpi.name + rpId}
                                    className={isSelected(rpId) ? '' : 'no-selected'}>
                                    <Typography align='center'>{t('common.noData')}</Typography>
                                </TableCellContentComparison>
                            )
                        }
                    })}
                    <TableCellContentStyled>
                        <Typography align='center'>{rpsKpi.units}</Typography>
                    </TableCellContentStyled>
                </TableRowContent>
            )
        })
    }

    return (
        <TableContainer component={Container}>
            <Table aria-label='response_plans_comparison_table' stickyHeader key='response_plans_comparison_table'>
                <TableHeadStyled>
                    <TableRow>
                        <TableCellHeaderStyled key='empty'></TableCellHeaderStyled>
                        {RPsNames.sort((a, b) => {
                            return sortRp(a, b, selectedResponsePlans)
                        }).map(responsePlanId => (
                            <TableCellHeaderStyled align='center' key={responsePlanId}>
                                <TitleWrapper className={isSelected(responsePlanId) ? '' : 'no-selected'}>
                                    <Checkbox
                                        checked={isSelected(responsePlanId)}
                                        onChange={handleSelectResponsePlan}
                                        value={responsePlanId}
                                        color='primary'
                                    />
                                    <TableCellHeaderTypography align='center'>
                                        {responsePlanId === '0' ? 'DN' : 'RP' + responsePlanId}
                                    </TableCellHeaderTypography>
                                </TitleWrapper>
                            </TableCellHeaderStyled>
                        ))}
                        <TableCellHeaderStyled key='units'>
                            <TableCellHeaderTypography align='center'>{'Units'}</TableCellHeaderTypography>
                        </TableCellHeaderStyled>
                    </TableRow>
                </TableHeadStyled>
                <TableBody>{renderKpiRows(KPIs)}</TableBody>
            </Table>
        </TableContainer>
    )
}
