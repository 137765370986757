import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { CloseButton } from '../../systemStatus/SystemStatusStyles'
import { Title } from '../../../containers/manageContainer/components/tasks/TaskDialogStyles'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { useTranslation } from 'react-i18next'

export const ConfirmLogOutDialog: React.FC<IConfirmLogOutDialogProps> = ({
    open,
    onClose,
    onConfirm,
}: IConfirmLogOutDialogProps) => {
    const { t } = useTranslation()

    return (
        <Dialog onClose={onClose} aria-labelledby='charts-dialog-title' open={open} disableEscapeKeyDown={true}>
            <Title variant='h2'>{t('sideBar.confirmSignOutTitle')}</Title>
            <CloseButton onClick={() => onClose(true)}>
                <CloseIcon />
            </CloseButton>
            <DialogContent>{t('sideBar.confirmSignOutMessage')}</DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(true)}>{t('buttons.cancel')}</Button>
                <Button onClick={onConfirm} autoFocus>
                    {t('buttons.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
