const externalImg = require('../../app/icons/incidents/external.png')
const externalActiveImg = require('../../app/icons/incidents/external_active.png')
const detectedImg = require('../../app/icons/incidents/detected.png')
const detectedActiveImg = require('../../app/icons/incidents/detected_active.png')
const realImg = require('../../app/icons/incidents/real.png')
const realActiveImg = require('../../app/icons/incidents/real_active.png')
const testImg = require('../../app/icons/incidents/test.png')
const testActiveImg = require('../../app/icons/incidents/test_active.png')
const riskPrediction80 = require('../../app/icons/incidents/riskprediction_80.png')
const riskPrediction90 = require('../../app/icons/incidents/riskprediction_90.png')
const riskPrediction100 = require('../../app/icons/incidents/riskprediction_100.png')
const trafficAction = require('../../app/icons/actionIconMarker.png')
const trafficActionRed = require('../../app/icons/actionIconMarkerRed.png')
const trafficActionBlue = require('../../app/icons/actionIconMarkerBlue.png')
const trafficActionGrey = require('../../app/icons/actionIconMarkerGrey.png')
const speedRecommendation60 = require('../../app/icons/sppedRecomendation/60_speed_recommendation.png')
const speedRecommendation70 = require('../../app/icons/sppedRecomendation/70_speed_recommendation.png')
const speedRecommendation80 = require('../../app/icons/sppedRecomendation/80_speed_recommendation.png')
const speedRecommendation90 = require('../../app/icons/sppedRecomendation/90_speed_recommendation.png')
const speedRecommendation100 = require('../../app/icons/sppedRecomendation/100_speed_recommendation.png')
const speedRecommendation60Generated = require('../../app/icons/sppedRecomendation/60_speed_recommendation_generated.png')
const speedRecommendation70Generated = require('../../app/icons/sppedRecomendation/70_speed_recommendation_generated.png')
const speedRecommendation80Generated = require('../../app/icons/sppedRecomendation/80_speed_recommendation_generated.png')
const speedRecommendation90Generated = require('../../app/icons/sppedRecomendation/90_speed_recommendation_generated.png')
const speedRecommendation100Generated = require('../../app/icons/sppedRecomendation/100_speed_recommendation_generated.png')

export const getIncidentIcon = (type: string | undefined) => {
    switch (type) {
        case 'external':
            return externalImg
        case 'external_active':
            return externalActiveImg
        case 'detected':
            return detectedImg
        case 'detected_active':
            return detectedActiveImg
        case 'real':
            return realImg
        case 'real_active':
            return realActiveImg
        case 'test':
            return testImg
        case 'test_active':
            return testActiveImg
        case 'risk_prediction':
            return detectedImg
        case 'traffic_actions':
            return trafficAction
        default:
            return undefined
    }
}

export const getSpeedRecommendationIcon = (value: number | undefined, confirmed: boolean | undefined) => {
    switch (value) {
        case 100:
            return confirmed !== undefined ? confirmed ? speedRecommendation100 : speedRecommendation100Generated : speedRecommendation100
        case 90:
            return confirmed !== undefined ? confirmed ? speedRecommendation90 :speedRecommendation90Generated : speedRecommendation90
        case 80:
            return confirmed !== undefined ? confirmed ? speedRecommendation80 : speedRecommendation80Generated : speedRecommendation80
        case 70:
            return confirmed !== undefined ? confirmed ? speedRecommendation70 : speedRecommendation70Generated : speedRecommendation70
        case 60:
            return confirmed !== undefined ? confirmed ? speedRecommendation60 : speedRecommendation60Generated : speedRecommendation60
        default:
            return speedRecommendation60Generated
    }
}

export const loadMapboxImages = ($mapbox: any) => {
    $mapbox.loadImage(externalImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('external', image)
        }
    })

    $mapbox.loadImage(externalActiveImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('external_active', image)
        }
    })

    $mapbox.loadImage(detectedImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('detected', image)
        }
    })

    $mapbox.loadImage(detectedActiveImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('detected_active', image)
        }
    })

    $mapbox.loadImage(realImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('real', image)
        }
    })

    $mapbox.loadImage(realActiveImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('real_active', image)
        }
    })

    $mapbox.loadImage(testImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('test', image)
        }
    })

    $mapbox.loadImage(testActiveImg, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('test_active', image)
        }
    })

    $mapbox.loadImage(riskPrediction80, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('risk_prediction_80', image)
        }
    })

    $mapbox.loadImage(riskPrediction90, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('risk_prediction_90', image)
        }
    })

    $mapbox.loadImage(riskPrediction100, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('risk_prediction_100', image)
        }
    })

    $mapbox.loadImage(trafficAction, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('traffic_actions_active', image)
        }
    })

    $mapbox.loadImage(trafficActionRed, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('traffic_actions_selected', image)
        }
    })

    $mapbox.loadImage(trafficActionBlue, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('traffic_actions_hover', image)
        }
    })

    $mapbox.loadImage(trafficActionGrey, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('traffic_actions_disabled', image)
        }
    })

    //TODO agregar los iconos correspondientes a la speed recommendation en millas
    $mapbox.loadImage(speedRecommendation60, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_60', image)
        }
    })

    $mapbox.loadImage(speedRecommendation70, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_70', image)
        }
    })

    $mapbox.loadImage(speedRecommendation80, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_80', image)
        }
    })

    $mapbox.loadImage(speedRecommendation90, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_90', image)
        }
    })

    $mapbox.loadImage(speedRecommendation100, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_100', image)
        }
    })

    $mapbox.loadImage(speedRecommendation60Generated, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_60_generated', image)
        }
    })

    $mapbox.loadImage(speedRecommendation70Generated, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_70_generated', image)
        }
    })

    $mapbox.loadImage(speedRecommendation80Generated, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_80_generated', image)
        }
    })

    $mapbox.loadImage(speedRecommendation90Generated, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_90_generated', image)
        }
    })

    $mapbox.loadImage(speedRecommendation100Generated, (error: any, image: any) => {
        if (error) {
            console.error(error)
        } else {
            $mapbox.addImage('speed_recommendation_100_generated', image)
        }
    })
}
