import { FC, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
    selectOpenResponsePlanCreateDialog,
    selectResponsePlanSelected,
    setOpenResponsePlanCreateDialog,
    setResponsePlanSelected,
} from '../../../../features/responsePlanToolbar/store/responsePlanSlice'
import {
    loadManageLiveResponsePlan,
    selectResponsePlanLives,
} from '../../../../features/evaluationsSelector/newManageLiveEvaluationDialog/store/responsePlanManageLiveSlice'
import { selectSelectedResponsePlan } from './responsePlanListSelectorSlice'
import { selectEpoch } from '../../../../features/core/coreSlice'
import { loadListActions } from '../../../../features/actionsToolbar/store/actionsSlice'
import { loadListEvents } from '../../../../features/eventsToolbar/store/eventsSlice'
import ResponsePlanSelectorDialog from '../responsePlanSelectorDialog/responsePlanSelectorDialog'
import { t } from 'i18next'
import CustomIcon from '../../../../components/customIcon/CustomIcon'
import {
    ResponsePlanToolbarIcon,
    ResponsePlanToolbarLabel,
} from '../../../../features/responsePlanToolbar/responsePlanToolbarStyles'
import { ResponsePlanSelectorWrapper } from './responsePlanListSelectorStyles'
import { restoreEvents } from '../../../../features/mapbox/mapboxSlice'

const ResponsePlanListSelector: FC<ResponsePlanListSelectorProps> = ({
    setResponsePlan,
}: ResponsePlanListSelectorProps) => {
    const _responsePlanLives: IResponsePlan[] = useAppSelector(selectResponsePlanLives)
    const _selectedResponsePlan = useAppSelector(selectSelectedResponsePlan)
    const _responsePlanIsSelected: boolean = useAppSelector(selectResponsePlanSelected)
    const _openResponsePlanCreateDialog: boolean = useAppSelector(selectOpenResponsePlanCreateDialog)
    const _epoch: number = useAppSelector(selectEpoch)
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!_openResponsePlanCreateDialog) {
            dispatch(setResponsePlanSelected(false))
            dispatch(restoreEvents())
        }
    }, [_openResponsePlanCreateDialog, _selectedResponsePlan, dispatch, _epoch])

    useEffect(() => {
        if (_selectedResponsePlan.id! > 0) {
            dispatch(setOpenResponsePlanCreateDialog(true))
            dispatch(setResponsePlanSelected(true))
        }
    }, [_selectedResponsePlan, dispatch])

    useEffect(() => {
        dispatch(loadManageLiveResponsePlan())
    }, [dispatch])

    useEffect(() => {
        if (_responsePlanIsSelected && _openResponsePlanCreateDialog) {
            dispatch(loadListEvents())
            dispatch(loadListActions())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_responsePlanIsSelected, _openResponsePlanCreateDialog])

    const handleResponsePlanToolbarOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (_responsePlanIsSelected) {
            dispatch(setResponsePlanSelected(false))
            dispatch(setOpenResponsePlanCreateDialog(false))
        } else {
            setOpenDialog(true)
            dispatch(setResponsePlanSelected(true))
        }
    }

    const handleResponsePlanDialogClose = (cancel: boolean) => {
        setOpenDialog(false)
        if (cancel) {
            dispatch(setResponsePlanSelected(false))
        }
    }

    return (
        <>
            <ResponsePlanSelectorWrapper onClick={handleResponsePlanToolbarOnClick}>
                <ResponsePlanToolbarIcon
                    className={`${_responsePlanIsSelected ? 'open-responsePlanToolbar-dialog' : ''}`}>
                    <CustomIcon name={'responsePlan'} />
                </ResponsePlanToolbarIcon>
                <ResponsePlanToolbarLabel
                    className={`${_responsePlanIsSelected ? 'open-responsePlanToolbar-dialog' : ''}`}>
                    {t('manageResponsePLan.responsePlan')}
                </ResponsePlanToolbarLabel>
            </ResponsePlanSelectorWrapper>

            <ResponsePlanSelectorDialog
                open={openDialog}
                onClose={handleResponsePlanDialogClose}
                responsePlans={_responsePlanLives}
                setSelectedResponsePlan={setResponsePlan}
                selectedResponsePlan={_selectedResponsePlan.id || -1}
            />
        </>
    )
}

export default ResponsePlanListSelector
