import { all, AllEffect, call, ForkEffect, put, takeLatest } from 'redux-saga/effects'
import {
    createNewResponsePlan,
    deleteResponsePlan,
    setResponsePlanCreated,
    setResponsePlanCreating,
    updateResponsePlan,
} from './responsePlanSlice'
import {
    createManageResponsePlan,
    deleteManageResponsePlan,
    updateManageResponsePlan,
} from '../../../services/responsePlans/responsePlansService'
import { setStaticResponsePlanListSelectorRemoved } from '../../../containers/manageContainer/components/responsePlanListSelector/responsePlanListSelectorSlice'

function* createNewManageResponsePlan(action: any): any {
    try {
        yield call(createManageResponsePlan, action.payload.responsePlan)
        yield put(setResponsePlanCreated(true))
        yield put(setResponsePlanCreating(false))
    } catch (error) {
        console.error(error)
    }
}

function* deleteResponsePlanById(action: any): any {
    yield call(deleteManageResponsePlan, action.payload.responsePlan)
    yield put(setStaticResponsePlanListSelectorRemoved(true))
}

function* updateResponsePlanById(action: any): any {
    try {
        yield call(updateManageResponsePlan, action.payload.responsePlan)
        yield put(setResponsePlanCreated(true))
        yield put(setResponsePlanCreating(false))
    } catch (error) {
        console.error(error)
    }
}

function* responsePlanSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(createNewResponsePlan, createNewManageResponsePlan),
        yield takeLatest(updateResponsePlan, updateResponsePlanById),
        yield takeLatest(deleteResponsePlan, deleteResponsePlanById),
    ])
}

export default responsePlanSaga
