import React, { Suspense, useEffect, useState } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { selectConfig, initialSetup, selectMainConfig } from './features/core/coreSlice'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { ThemeProvider } from '@mui/material/styles'
import DashboardContainer from './containers/dashboardContainer/DashboardContainer'
import MonitorContainer from './containers/monitorContainer/MonitorContainer'
import AnalyzeContainer from './containers/analyzeContainer/AnalyzeContainer'
import LoaderContainer from './containers/loaderContainer/LoaderContainer'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './App.css'
import LandUseContainer from './containers/landuseContainer/LandUseContainer'
import CurrentErrorDialog from './components/currentErrorDialog/currentErrorDialog'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import ManagerManageContainer from './containers/manageContainer/ManagerManageContainer'

const App: React.FC = () => {
    const configSelector: IModuleConfig = useAppSelector(selectConfig)
    const _mainConfig: IMainConfig = useAppSelector(selectMainConfig)
    const dispatch = useAppDispatch()
    const [isI18nInitialised, setIsI18nInitialised] = useState(false)

    const browserRouterInput: any = generateBrowserRouterInput(configSelector)
    const appRouterInitial = createBrowserRouter(browserRouterInput)

    useEffect(() => {
        dispatch(initialSetup())
    }, [dispatch])

    useEffect(() => {
        document.title = _mainConfig.defaults['live-name'] && _mainConfig.defaults['live-name'] !== '' ?
            _mainConfig.defaults['live-name'] : 'Aimsun'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_mainConfig]);

    useEffect(() => {
        async function initializeI18n() {
            await i18n
                .use(Backend)
                .use(LanguageDetector)
                .use(initReactI18next)
                .init({
                    fallbackLng: 'en',
                    debug: true,
                    interpolation: {
                        escapeValue: false,
                    },
                    backend: {
                        requestOptions: {
                            cache: 'no-store',
                        },
                    },
                })
            setIsI18nInitialised(true)
        }

        initializeI18n()
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense>{isI18nInitialised && <RouterProvider router={appRouterInitial} />}</Suspense>
            <CurrentErrorDialog />
        </ThemeProvider>
    )
}

export default App

const getModuleComponent = (name: string, options?: any) => {
    const containers: any = {
        loader: <LoaderContainer />,
        monitor: <MonitorContainer options={options} />,
        analyze: <AnalyzeContainer options={options} />,
        'Land use': <LandUseContainer options={options} />,
        manage: <ManagerManageContainer options={options} />,
        dashboard: <DashboardContainer />,
    }
    return containers[name]
}

const generateBrowserRouterInput = (config: IModuleConfig) => {
    let modules = config.modules
    const modulesRoutes = modules
        .filter((module: IModule) => module.type === 'container')
        .map((module: IModule) => {
            const moduleElement = getModuleComponent(module.name, module.options)
            return {
                path: `${module.url}`,
                element: moduleElement,
            }
        })
    modulesRoutes.push({ path: '/', element: getModuleComponent('loader') })
    modulesRoutes.push({ path: '*', element: getModuleComponent('loader') })
    return modulesRoutes
}
