import { FormControl, InputLabel } from '@mui/material'
import styled from '@emotion/styled'
import { NAVY_BLUE } from '../../../../theme'

export const ResponsePlanSelectorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 0 8px;
    margin: auto;
    &.disabled {
        pointer-events: none;
        opacity: 0.7;
    }
`

export const ResponsePlanSelectorListWrapper = styled.div`
    padding: 0 8px;
    min-width: 170px;
    position: relative;
    margin: auto;
`
export const ResponsePlanForm = styled(FormControl)`
    width: 100%;
    &.Mui-focused {
        color: inherit;
    }
    .response-plan-list-selector {
        &.selected {
            background-color: ${NAVY_BLUE};
            opacity: 0.6;
        }
        &:after {
            border-bottom-color: inherit;
        }
    }
`
export const ResponsePlanInputLabel = styled(InputLabel)`
    &.Mui-focused {
        color: inherit;
    }
`
