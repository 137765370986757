import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export function getStationRealData({
    feature_id,
    viewMode,
    t,
    n_hours,
}: {
    feature_id: number
    t: number
    viewMode: IViewMode
    n_hours: number
}): Promise<AxiosResponse<any, any>> {
    return getRequestAPI(
        `/api_dm/rpc/get_last_n_hours?feature_id=${feature_id}&variable=${viewMode.variable}&t=${t}&n_hours=${n_hours}`
    )
}

export function getStationPredictionData({
    feature_id,
    viewMode,
    typeFeature,
    t,
    horizon,
}: {
    feature_id: number
    typeFeature: string
    t: number
    viewMode: IViewMode
    horizon: number
}): Promise<AxiosResponse<any, any>> {
    const type = typeFeature === 'GKDetector' ? viewMode.sources.stations.future : viewMode.sources.sections.future
    return getRequestAPI(
        `/api_dm/rpc/get_prediction_data_var?feature_id=${feature_id}&variable=${viewMode.variable}&t=${t}&type=${type}&horizon=${horizon}`,
        {cache: false}
    )
}

export function getStationPredictionAnalyticalOrSimulatedData({
    feature_id,
    viewMode,
    t,
    horizon,
    typePrediction
}: {
    feature_id: number
    typeFeature: string
    t: number
    viewMode: IViewMode
    horizon: number
    typePrediction: 'analytical' | 'simulated'
}): Promise<AxiosResponse<any, any>> {
    return getRequestAPI(
        `/api_dm/rpc/get_prediction_data_var?feature_id=${feature_id}&variable=${viewMode.variable}&t=${t}&type=${typePrediction}&horizon=${horizon}`,
        {cache: false}
    )
}

export function getStationPatternData({
    feature_id,
    viewMode,
    t,
    n_hours,
    pattern_id,
}: {
    feature_id: string
    t: number
    viewMode: IViewMode
    n_hours: number
    pattern_id: number
}): Promise<AxiosResponse<any, any>> {
    return getRequestAPI(
        `/api_dm/rpc/get_pattern_last_n_hours?feature_id=${feature_id}&variable=${viewMode.variable}&t=${t}&n_hours=${n_hours}&pattern_id=${pattern_id}`
    )
}

export function getStationSensorReliabilityData({
    feature_id,
    viewMode,
    t,
    n_hours,
}: {
    feature_id: number
    t: number
    viewMode: IViewMode
    n_hours: number
}): Promise<AxiosResponse<any, any>> {
    return getRequestAPI(
        `/api_dm/rpc/get_last_n_hours?feature_id=${feature_id}&variable=sensor_reliability_${viewMode.variable}&t=${t}&n_hours=${n_hours}`
    )
}
