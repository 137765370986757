import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { NO_DATA } from '../../../theme'

export interface IStationSelectedData {
    feature_id: string
    valueColor: string
    reliabilityColor: string
    typeFeature: string
}

export interface IStationSelectedState {
    stationRealData: []
    stationPredictionData: []
    stationSimulatedPredictionData: []
    stationAnalyticalPredictionData: []
    stationPatternData: []
    stationReliabilityData: []
    feature: IStationSelectedData
    fetching: boolean
    openChart: boolean
}
export interface IStationByMap {
    stationData: IStationSelectedState
    mapId: number
}

export interface IStationsSelectedState {
    stations: IStationByMap[]
}

const stationSelectedInitialData: IStationSelectedData = {
    feature_id: 'unknown',
    valueColor: NO_DATA,
    reliabilityColor: NO_DATA,
    typeFeature: '',
}

const stationDataInitial: IStationSelectedState = {
    stationRealData: [],
    stationPredictionData: [],
    stationSimulatedPredictionData: [],
    stationAnalyticalPredictionData: [],
    stationPatternData: [],
    stationReliabilityData: [],
    feature: stationSelectedInitialData,
    fetching: false,
    openChart: false,
}

const initialStationsState: IStationsSelectedState = {
    stations: [
        {
            stationData: stationDataInitial,
            mapId: 0,
        },
    ],
}

const stationsSelectedSlice = createSlice({
    name: 'stationsSelected',
    initialState: initialStationsState,
    reducers: {
        storeRealDataStation: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            state.stations[stationIndexByMapId].stationData.stationRealData = action.payload.realData
        },
        storePredictionDataStation: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            state.stations[stationIndexByMapId].stationData.stationPredictionData = action.payload.predictionData
        },
        storeAnalyticalPredictionDataStation: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            state.stations[stationIndexByMapId].stationData.stationAnalyticalPredictionData = action.payload.analyticalData
        },
        storeSimulatePredictionDataStation: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            state.stations[stationIndexByMapId].stationData.stationSimulatedPredictionData = action.payload.simulatedData
        },
        storePatternDataStation: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            state.stations[stationIndexByMapId].stationData.stationPatternData = action.payload.patternData
        },
        storeReliabilityDataStation: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            state.stations[stationIndexByMapId].stationData.stationReliabilityData = action.payload.reliabilityData
        },
        setFetchingStationData: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => {
                return station.mapId === action.payload.mapId
            })
            state.stations[stationIndexByMapId].stationData.fetching = action.payload.fetching
        },
        loadStationSelected: (
            state,
            action: PayloadAction<{
                mapId: number
                feature_id: string
                t: number
                viewMode: IViewMode
                valueColor: string
                reliabilityColor: string
                typeFeature: string
            }>
        ): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            const currentStation = state.stations[stationIndexByMapId]
            const resetStation: IStationByMap = {
                stationData: {
                    ...stationDataInitial,
                    fetching: true,
                    feature: {
                        feature_id: action.payload.feature_id,
                        valueColor: action.payload.valueColor,
                        reliabilityColor: action.payload.reliabilityColor,
                        typeFeature: action.payload.typeFeature,
                    },
                    openChart: currentStation?.stationData.openChart,
                },
                mapId: action.payload.mapId,
            }

            if (stationIndexByMapId !== -1) {
                state.stations[stationIndexByMapId] = resetStation
            } else {
                state.stations.push(resetStation)
            }
        },
        setOpenChart: (state, action: PayloadAction<any>): void => {
            const stationIndexByMapId = state.stations.findIndex(station => station.mapId === action.payload.mapId)
            state.stations[stationIndexByMapId].stationData.openChart = action.payload.openChart
        },
        restoreStationSelected: state => {
            state.stations = [
                {
                    stationData: stationDataInitial,
                    mapId: 0,
                },
            ]
        },
    },
})

export const {
    storeRealDataStation,
    storePredictionDataStation,
    storeAnalyticalPredictionDataStation,
    storeSimulatePredictionDataStation,
    storePatternDataStation,
    storeReliabilityDataStation,
    setFetchingStationData,
    loadStationSelected,
    setOpenChart,
    restoreStationSelected,
} = stationsSelectedSlice.actions

export const selectStationRealData = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.stationRealData : []
}
export const selectStationPredictionData = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.stationPredictionData : []
}

export const selectStationAnalyticalPredictionData = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.stationAnalyticalPredictionData : []
}

export const selectStationSimulatedPredictionData = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.stationSimulatedPredictionData : []
}
export const selectStationPatternData = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.stationPatternData : []
}
export const selectStationReliabilityData = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.stationReliabilityData : []
}
export const selectStationFetching = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.fetching : false
}

export const selectStationFeatureId = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.feature : stationSelectedInitialData
}

export const selectOpenChartStation = (state: RootState, mapId: number) => {
    const stationByMap = state.stationsSelectedSlice.stations.find(station => station.mapId === mapId)
    return stationByMap ? stationByMap.stationData.openChart : false
}

export default stationsSelectedSlice.reducer
