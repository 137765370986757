import * as React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import { CloseRounded as CloseIcon, ExpandMore } from '@mui/icons-material'

import {
    Alert,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material'
import SearchTermComponent from '../../components/searchTermComponent/SearchTermComponent'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../app/hooks'
import {
    createNewResponsePlan,
    selectOpenResponsePlanCreateDialog,
    selectResponsePlanCreated,
    selectResponsePlanCreating,
    setOpenResponsePlanCreateDialog,
    setResponsePlanCreated,
    setResponsePlanSelected,
    updateResponsePlan,
} from '../responsePlanToolbar/store/responsePlanSlice'
import {
    CardResponsePlanDialogStyled,
    ResponsePlanDialogAccordionStyled,
    ResponsePlanDialogAccordionSummaryStyled,
    ResponsePlanDialogBadgeStyled,
    ResponsePlanDialogButton,
    ResponsePlanDialogButtonsWrapper,
    ResponsePlanDialogContentStyled,
    ResponsePlanDialogEventsActionsWrapper,
    ResponsePlanDialogIconTitleWrapper,
    ResponsePlanDialogListWrapper,
    ResponsePlanDialogName,
    ResponsePlanDialogResetButton,
    ResponsePlanDialogSeparator,
    ResponsePlanDialogSnackbarStyled,
    ResponsePlanDialogTitle,
    ResponsePlanDialogTitleInfoWrapper,
    ResponsePlanDialogTitleStyled,
    ResponsePlanDialogTitleWrapper,
    ResponsePlanSearchTermWrapper,
    ResponsePlanSelectActionsEventsWrapper,
} from './responsePlanCreateDialogStyled'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { selectListActions } from '../actionsToolbar/store/actionsSlice'
import { getNames, selectAvailableNames } from '../core/coreSlice'

import { loadManageLiveResponsePlan } from '../evaluationsSelector/newManageLiveEvaluationDialog/store/responsePlanManageLiveSlice'
import {
    initialSelectedResponsePlanState,
    selectHoverResponsePlanAction,
    selectSelectedResponsePlan,
    selectSelectedResponsePlanActions,
    storeHoverResponsePlanAction,
    storeSelectedResponsePlan,
    storeSelectedResponsePlanActions,
} from '../../containers/manageContainer/components/responsePlanListSelector/responsePlanListSelectorSlice'
import { selectTrafficActions } from '../mapbox/mapboxSlice'

const typeToFind = {
    event: 'event',
    action: 'action',
}

export const ResponsePlanCreateDialog: React.FC = () => {
    const { t } = useTranslation()
    const _openResponsePlanCreateDialog: boolean = useAppSelector(selectOpenResponsePlanCreateDialog)
    const _listActions: any[] = useAppSelector(selectListActions)
    const _responsePlanCreating: boolean = useAppSelector(selectResponsePlanCreating)
    const _responsePlanMade: boolean = useAppSelector(selectResponsePlanCreated)
    const _selectedResponsePlan: IResponsePlan = useAppSelector(selectSelectedResponsePlan)
    const _availableNames = useAppSelector(selectAvailableNames)
    const _trafficActions = useAppSelector(selectTrafficActions)
    const _selectedActions = useAppSelector(selectSelectedResponsePlanActions)
    const _hoverAction = useAppSelector(selectHoverResponsePlanAction)

    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const [feedback, setFeedback] = useState<{
        message: string
        type?: 'success' | 'info' | 'warning' | 'error'
        duration: number
    }>({
        message: '',
        duration: 0,
    })
    const [responsePlanName, setResponsePlanName] = useState<string>('')
    const [searchActionTerm, setSearchActionTerm] = useState<string>('')
    const [searchActionReset, setSearchActionReset] = useState<boolean>(false)
    const [actionsFiltered, setActionsFiltered] = useState<any[]>([])
    const [postingData, setPostingData] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    const invalidName: boolean = useMemo(() => {
        if (
            _selectedResponsePlan.id! > 0 &&
            _selectedResponsePlan.name.toLowerCase() === responsePlanName.toLowerCase()
        ) {
            return false
        }
        return _availableNames.response.map(name => name.toLowerCase()).includes(responsePlanName.toLowerCase())
    }, [_availableNames, _selectedResponsePlan, responsePlanName])

    const inUse: boolean = useMemo(() => _selectedResponsePlan.in_use!, [_selectedResponsePlan])

    const selectedActions = useMemo(
        () => _selectedActions?.features.map(feature => feature.properties!.id) || [],
        [_selectedActions]
    )

    const disabledActions = useMemo(
        () =>
            _trafficActions?.features
                .filter((feature: any) => feature.properties.disabled)
                .map((feature: any) => feature.properties!.id) || [],
        [_trafficActions]
    )

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getNames('response'))
    }, [dispatch])

    useEffect(() => {
        setActionsFiltered(_listActions)
    }, [_listActions])

    useEffect(() => {
        if (_selectedResponsePlan.id! > 0) {
            setResponsePlanName(_selectedResponsePlan.name)
        }
    }, [_selectedResponsePlan])

    useEffect(() => {
        if (!_responsePlanCreating && _responsePlanMade) {
            setPostingData(false)
            showEventFeedback(
                t(
                    _selectedResponsePlan.id! > 0
                        ? 'manageResponsePLan.responsePlanUpdated'
                        : 'manageResponsePLan.responsePlanCreated'
                ),
                'success'
            )
            dispatch(setResponsePlanCreated(false))
            dispatch(loadManageLiveResponsePlan())

            setTimeout(() => {
                setShowFeedback(false)
                setFeedback({
                    message: 'message',
                    duration: 0,
                })
                handleClose()
                setDisabled(false)
            }, 3000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_responsePlanCreating, _responsePlanMade])

    const handleClose = () => {
        dispatch(setOpenResponsePlanCreateDialog(false))
        dispatch(setResponsePlanSelected(false))
        dispatch(storeSelectedResponsePlan(initialSelectedResponsePlanState))
        setShowFeedback(false)
        setPostingData(false)
    }

    const handleFeedbackClose = () => {
        setShowFeedback(false)
    }

    const handleCheckedAction = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkedId = Number.parseInt(event.target.value)
        const checked = selectedActions.includes(checkedId)
        let features = _selectedActions?.features || []
        if (checked) {
            features = features.filter((feature: any) => feature.properties!.id !== checkedId)
        } else {
            const found = _trafficActions.features.find((feature: any) => feature.properties!.id === checkedId)
            if (found) {
                features = [...features, found]
            }
        }
        dispatch(
            storeSelectedResponsePlanActions({
                ..._selectedActions!,
                features,
            })
        )
    }

    const handleHoverAction = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
        const hoverActionId = Number((event.target as any).id)
        if (!inUse && !isActionDisabled(hoverActionId)) {
            if (event.type === 'mouseenter') {
                dispatch(storeHoverResponsePlanAction(hoverActionId))
            } else if (event.type === 'mouseleave') {
                dispatch(storeHoverResponsePlanAction(0))
            }
        }
    }

    const handleMouseLeaveAction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const hoverActionId = Number((event.target as any).id)
        if (!inUse && !isActionDisabled(hoverActionId)) {
            dispatch(storeHoverResponsePlanAction(0))
        }
    }

    const searchActionTermChange = (searchTerm: string) => {
        setSearchActionTerm(searchTerm)
        if (searchTerm !== '' && searchTerm.length > 2) {
            const searchActionResult = search(searchTerm, typeToFind.action)
            setActionsFiltered(searchActionResult)
            setSearchActionReset(true)
        } else {
            setSearchActionReset(false)
            setActionsFiltered(_listActions)
        }
    }

    const search = (value: string, type: string) => {
        const allToFind = _listActions
        const searchResult = allToFind.filter((action: any) => action.name.toLowerCase().includes(value.toLowerCase()))
        if (searchResult.length === 0) {
            showEventFeedback(`${t(`manageResponsePLan.${type}`)} ${t('manageResponsePLan.notFound')}`, 'warning')
        }
        return searchResult
    }

    const showEventFeedback = (message: string, type: 'success' | 'info' | 'warning' | 'error' = 'info') => {
        setShowFeedback(true)
        setFeedback({
            message: message,
            type: type,
            duration: 3000,
        })
        setTimeout(() => {
            setShowFeedback(false)
        }, 3000)
    }

    const resetActionsClick = () => {
        setSearchActionTerm('')
        setSearchActionReset(false)
        setActionsFiltered(_listActions)
    }

    const newResponsePlanHandler = () => {
        setPostingData(true)
        setDisabled(true)
        const responsePlan = {
            name: responsePlanName,
            actions: selectedActions,
        }
        if (_selectedResponsePlan.id! > 0) {
            dispatch(
                updateResponsePlan({
                    responsePlan: {
                        id: _selectedResponsePlan.id,
                        ...responsePlan,
                    },
                })
            )
        } else {
            dispatch(createNewResponsePlan({ responsePlan }))
        }
    }

    const enableRunButton = responsePlanName !== '' && selectedActions.length > 0
    const errorRpName = responsePlanName === ''

    const isActionDisabled = (actionId: number) => {
        return !selectedActions.includes(actionId) && disabledActions.includes(actionId)
    }

    const helperText: string = useMemo(() => {
        if (errorRpName) {
            return t('common.requiredField')
        } else if (invalidName) {
            return t('common.invalidName')
        }
        return ''
    }, [errorRpName, invalidName, t])

    return (
        <CardResponsePlanDialogStyled hidden={!_openResponsePlanCreateDialog}>
            <ResponsePlanDialogTitleStyled id='response-plan-manage-dialog-title'>
                <ResponsePlanDialogTitleInfoWrapper>
                    <ResponsePlanDialogTitleWrapper>
                        <ResponsePlanDialogIconTitleWrapper>
                            <CustomIcon name='responsePlan' style={{ marginTop: '0' }} />
                            <ResponsePlanDialogTitle variant='h2'>
                                {t(
                                    _selectedResponsePlan.id! > 0
                                        ? 'manageResponsePLan.responsePlan'
                                        : 'manageResponsePLan.newResponsePlan'
                                )}
                            </ResponsePlanDialogTitle>
                        </ResponsePlanDialogIconTitleWrapper>
                        <IconButton aria-label='delete' onClick={handleClose} style={{ width: '30px', height: '30px' }}>
                            <CloseIcon />
                        </IconButton>
                    </ResponsePlanDialogTitleWrapper>
                </ResponsePlanDialogTitleInfoWrapper>
                <ResponsePlanDialogSeparator />
            </ResponsePlanDialogTitleStyled>
            <ResponsePlanDialogContentStyled id='response-dialog-content'>
                <ResponsePlanDialogName
                    id='responsePlanName'
                    value={responsePlanName}
                    placeholder={t('manageResponsePLan.insertNameResponsePlan')}
                    label={t('manageResponsePLan.responsePlanName')}
                    variant={'standard'}
                    autoFocus={true}
                    onChange={e => setResponsePlanName(e.target.value)}
                    style={{
                        width: '100%',
                        marginBottom: '20px',
                    }}
                    error={errorRpName || invalidName}
                    disabled={inUse}
                    helperText={helperText}
                />
                <ResponsePlanDialogEventsActionsWrapper>
                    <ResponsePlanDialogAccordionStyled defaultExpanded={true}>
                        <ResponsePlanDialogAccordionSummaryStyled
                            expandIcon={<ExpandMore />}
                            aria-controls='response-dialog-content-actions'
                            id='response-dialog-header-events'>
                            <ResponsePlanDialogBadgeStyled badgeContent={selectedActions.length} color='primary'>
                                <Typography variant='h2'>{t('manageResponsePLan.actions')}</Typography>
                            </ResponsePlanDialogBadgeStyled>
                        </ResponsePlanDialogAccordionSummaryStyled>
                        <ResponsePlanSelectActionsEventsWrapper>
                            {!inUse && (
                                <ResponsePlanSearchTermWrapper>
                                    <SearchTermComponent
                                        value={searchActionTerm}
                                        startAdornment
                                        searchTermChange={searchActionTermChange}
                                        placeholder={t('manageResponsePLan.filter3chars')}
                                        id='searchEventsInput'
                                    />
                                    {searchActionReset && (
                                        <ResponsePlanDialogResetButton onClick={resetActionsClick} variant='contained'>
                                            <Typography variant='button'>{t('manageResponsePLan.reset')}</Typography>
                                        </ResponsePlanDialogResetButton>
                                    )}
                                </ResponsePlanSearchTermWrapper>
                            )}
                            <ResponsePlanDialogListWrapper>
                                {Array.isArray(actionsFiltered) && (
                                    <FormControl component='fieldset'>
                                        <FormGroup onMouseLeave={handleMouseLeaveAction}>
                                            {actionsFiltered.map((action: any) => {
                                                return (
                                                    <Tooltip
                                                        key={action.id}
                                                        title={
                                                            isActionDisabled(action.id)
                                                                ? t('manageResponsePLan.sectionInAnotherTrafficAction')
                                                                : ''
                                                        }
                                                        placement='left'>
                                                        <FormControlLabel
                                                            id={action.id}
                                                            className={action.id === _hoverAction ? 'hover' : ''}
                                                            onMouseEnter={handleHoverAction}
                                                            control={
                                                                <Checkbox
                                                                    id={action.id}
                                                                    checked={selectedActions.includes(action.id)}
                                                                    onChange={handleCheckedAction}
                                                                    value={action.id}
                                                                    color={
                                                                        action.id === _hoverAction ? 'info' : 'primary'
                                                                    }
                                                                />
                                                            }
                                                            label={`${action.name}`}
                                                            disabled={inUse || isActionDisabled(action.id)}
                                                        />
                                                    </Tooltip>
                                                )
                                            })}
                                        </FormGroup>
                                    </FormControl>
                                )}
                            </ResponsePlanDialogListWrapper>
                        </ResponsePlanSelectActionsEventsWrapper>
                    </ResponsePlanDialogAccordionStyled>
                </ResponsePlanDialogEventsActionsWrapper>
            </ResponsePlanDialogContentStyled>
            {_selectedResponsePlan.in_use! && (
                <Alert severity={'warning'} sx={{ width: '100%' }}>
                    {t('manageResponsePLan.responsePlanInUseAlert')}
                </Alert>
            )}
            <ResponsePlanDialogButtonsWrapper>
                <ResponsePlanDialogButton onClick={handleClose} variant='text'>
                    <Typography variant='button' color='primary'>
                        {t('buttons.cancel')}
                    </Typography>
                </ResponsePlanDialogButton>
                <ResponsePlanDialogButton
                    disabled={!enableRunButton || invalidName || inUse || disabled}
                    onClick={newResponsePlanHandler}
                    variant='contained'>
                    {(postingData && <PulseLoader size={8} margin={2} color='#FFF' />) || (
                        <Typography variant='button'>
                            {t(_selectedResponsePlan.id! > 0 ? 'buttons.edit' : 'buttons.create')}
                        </Typography>
                    )}
                </ResponsePlanDialogButton>
            </ResponsePlanDialogButtonsWrapper>
            {showFeedback && (
                <ResponsePlanDialogSnackbarStyled
                    open={showFeedback}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={feedback.duration}
                    onClose={handleFeedbackClose}>
                    <Alert onClose={handleFeedbackClose} severity={feedback.type} sx={{ width: '100%' }}>
                        {feedback.message}
                    </Alert>
                </ResponsePlanDialogSnackbarStyled>
            )}
        </CardResponsePlanDialogStyled>
    )
}
