import * as React from 'react'
import { useState } from 'react'
import { Container, Table, TableBody, TableContainer, TableRow } from '@mui/material'
import { DateTime } from 'luxon'
import {
    TableCellContentStyled,
    TableCellHeaderStyled,
    TableCellHeaderTypography,
    TableHeadStyled,
    TableRowContent,
} from '../../../../../../components/tableStyles/tabletyles'
import SearchTermComponent from '../../../../../../components/searchTermComponent/SearchTermComponent'
import { SearchTermWrapper } from './KpiDialogStyles'
import { KPIsDialogProps } from './KpiDialogTypes'
import CustomDialog from '../../../../../../components/customDialog/customDialog'
import { IRawKpi } from '../../store/kpisSlice'
import { useTranslation } from 'react-i18next'

const KpiDialog = ({ open, kpis, onClose, dateFormat, timeZone }: KPIsDialogProps) => {
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('')

    const handleClose = () => {
        onClose()
        setSearchTerm('')
    }

    const searchTermChange = (searchTerm: string) => {
        setSearchTerm(searchTerm.toLowerCase())
    }

    return (
        <CustomDialog handleClose={handleClose} title={t('kpi.kpis')} open={open}>
            <TableContainer component={Container} sx={{ marginBottom: '30px' }}>
                <SearchTermWrapper>
                    <SearchTermComponent
                        searchTermChange={searchTermChange}
                        iconColor='inherit'
                        startAdornment={true}
                        placeholder={t('search.searchKpi')}
                        disableUnderline={false}
                    />
                </SearchTermWrapper>
                <Table aria-label='response plans table' key='response plans table'>
                    <TableHeadStyled>
                        <TableRow>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>{t('kpi.kpi')}</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>{t('kpi.value')}</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>{t('kpi.units')}</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='center'>
                                <TableCellHeaderTypography>{t('kpi.horizon')}</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>{t('kpi.time')}</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                        </TableRow>
                    </TableHeadStyled>
                    <TableBody className='table__body'>
                        {kpis &&
                            kpis
                                .filter((kpi: IRawKpi) => {
                                    return (
                                        kpi.name.toLowerCase().includes(searchTerm) ||
                                        kpi.units.toLowerCase().includes(searchTerm) ||
                                        kpi.horizon.toString(10).includes(searchTerm)
                                    )
                                })
                                .map((kpi: IRawKpi, idx: number) => {
                                    return (
                                        <TableRowContent
                                            key={'kpi_' + idx}
                                            className={idx % 2 === 0 ? 'table__row--even' : 'table__row--odd'}>
                                            <TableCellContentStyled>{kpi.kpi}</TableCellContentStyled>
                                            <TableCellContentStyled>
                                                {kpi.value === -1 ? t('kpi.NA') : kpi.value.toFixed(2)}
                                            </TableCellContentStyled>
                                            <TableCellContentStyled>{kpi.units}</TableCellContentStyled>
                                            <TableCellContentStyled align='center'>
                                                {kpi.horizon}
                                            </TableCellContentStyled>
                                            <TableCellContentStyled>
                                                {DateTime.fromMillis(kpi.time, { zone: timeZone }).toFormat(dateFormat)}
                                            </TableCellContentStyled>
                                        </TableRowContent>
                                    )
                                })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialog>
    )
}

export default KpiDialog
