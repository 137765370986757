import React, { useEffect, useState } from 'react'
import { ClockPickerView, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { ClockInputRender } from './clockInputRender'
import { FirstPage, LastPage, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { addTimeToDate, subtractTimeToDate } from '../../helpers/DateTimeHelper'
import { TextFieldProps } from '@mui/material'
import { ClockWrapper, ClockStyledIconButton, StyledMobileDateTimePicker } from './clockStyles'
import { useAppDispatch, useAppSelector, useFetchingData } from '../../app/hooks'
import {
    selectConfig,
    selectEpoch,
    selectEpochChange,
    selectNowTime,
    selectTimeZone,
    storeEpoch,
    storeEpochChange,
} from '../core/coreSlice'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { selectDailyReportFlag, selectListEventsFlag, toggleDailyReport, toggleListEvents } from '../mapbox/mapboxSlice'
import { restoreInitialReportInfo } from '../dailyReportModal/store/dailyReportSlice'
import { loadMonthAvailableData } from '../../containers/dashboardContainer/DashboardContainerSlice'

const Clock: React.FC<IClockProps> = ({
    isDisabled = false,
    minutesStep = 0,
    longTimeStep = { number: 0, unit: 'hours' },
    showArrowProgress,
    updateDataWithNowTime,
    allowFuture,
    allowPast,
    availableData,
    availableDatesAndTimes,
    availableMaxAndMinTimesWithData,
    disabledNoAvailableDate,
}: IClockProps) => {
    const { t, i18n } = useTranslation()
    const _config: IModuleConfig = useAppSelector(selectConfig)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _epoch = useAppSelector(selectEpoch)
    const _nowTime = useAppSelector(selectNowTime)
    const _epochChange = useAppSelector(selectEpochChange)
    const _lListEventsFlag = useAppSelector(selectListEventsFlag)
    const _dailyReportFlag: boolean = useAppSelector(selectDailyReportFlag)
    const [value, setValue] = useState(DateTime.fromMillis(_nowTime, { zone: _timeZone }))
    const [maxDateTime, setMaxDateTime] = useState(DateTime.fromMillis(_nowTime, { zone: _timeZone }))
    const [minDateTime, setMinDateTime] = useState(DateTime.fromMillis(_nowTime, { zone: _timeZone }))
    const [comeFromCancel, setComeFromCancel] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const _fetchingData = useFetchingData()

    useEffect(() => {
        const nextDate: DateTime = DateTime.fromMillis(_epoch, { zone: _timeZone })
        setValue(nextDate)
        setComeFromCancel(false)
    }, [_epoch, _timeZone])

    useEffect(() => {
        setComeFromCancel(false)
    }, [availableData])

    useEffect(() => {
        const nextDate: DateTime = DateTime.fromMillis(_nowTime, { zone: _timeZone })
        if (_nowTime !== 0) {
            if (!_epochChange) {
                dispatch(storeEpoch(_nowTime))
                dispatch(storeEpochChange(true))
            } else {
                if (updateDataWithNowTime) {
                    dispatch(storeEpoch(_nowTime))
                }
            }
            if (!allowPast) {
                setMinDateTime(nextDate)
            }
            if (!allowFuture) {
                setMaxDateTime(nextDate)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_nowTime, _timeZone])

    const handleAccept = (date: DateTime) => {
        saveDate(date)
        closeListEvents()
    }

    const handleChange = (date: DateTime) => {
       if (date && disabledNoAvailableDate) {
            const maxAvailableDate = DateTime.fromMillis(Number(availableMaxAndMinTimesWithData.to), {
                zone: _timeZone,
            })
            const minAvailableDate = DateTime.fromMillis(Number(availableMaxAndMinTimesWithData.from), {
                zone: _timeZone,
            })
            if (date > maxAvailableDate) {
                setValue(maxAvailableDate)
            } else if (date < minAvailableDate) {
                setValue(minAvailableDate)
            } else {
                if (date.equals(DateTime.fromMillis(_epoch, { zone: _timeZone }))) {
                    setValue(date)
                    setComeFromCancel(true)
                    const epoch = DateTime.fromMillis(_epoch, { zone: _timeZone })
                    dispatch(loadMonthAvailableData({ month: epoch.month, year: epoch.year }))
                } else if(isCurrentTimeAvailable(date)){
                    setValue(date)
                } else {
                    const day = date.toISODate() || ''
                    const hour = date.get('hour').toString()
                    const availableTimes = availableDatesAndTimes[day]
                    const firstAvailableHour: any = Object.entries(availableTimes)[0]
                    const nextAvailableDate = availableDatesAndTimes[day][hour] ?
                        DateTime.fromISO(
                        `${day}T${Number(hour) < 10 ? '0' + hour : hour}:${availableDatesAndTimes[day][hour][0] === 0 ? '00' : availableDatesAndTimes[day][hour][0]}`, {
                        zone: _timeZone,
                            }) :
                        DateTime.fromISO(
                        `${day}T${Number(firstAvailableHour[0]) < 10 ? '0' + firstAvailableHour[0] : firstAvailableHour[0] }:${firstAvailableHour[1][0] === 0 ? '00' : firstAvailableHour[1][0]}`, {
                        zone: _timeZone,
                            })
                    setValue(nextAvailableDate)
                }
            }
        } else {
            setValue(date)
        }
    }

    const saveDate = (date: DateTime) => {
        dispatch(storeEpoch(date.toMillis()))
        setValue(date)
        if (_dailyReportFlag) {
            dispatch(toggleDailyReport(false))
            dispatch(restoreInitialReportInfo())
        }
    }

    const goLongTimeBack = () => {
        const nextDate: DateTime = subtractTimeToDate(value, longTimeStep.unit, longTimeStep.number)
        saveDate(nextDate)
        closeListEvents()
    }

    const goStepBack = () => {
        const nextDate: DateTime = subtractTimeToDate(value, 'minutes', minutesStep)
        saveDate(nextDate)
        closeListEvents()
    }

    const goStepForward = () => {
        const nextDate: DateTime = addTimeToDate(value, 'minutes', minutesStep)
        saveDate(nextDate)
        closeListEvents()
    }

    const goLongTimeForward = () => {
        const nextDate: DateTime = addTimeToDate(value, longTimeStep.unit, longTimeStep.number)
        saveDate(nextDate)
        closeListEvents()
    }

    const isCurrentTimeAvailable = (date: DateTime) => {
        if (date && disabledNoAvailableDate) {
            const nextDate: DateTime = date.setZone(_timeZone)
            return availableData.indexOf(nextDate.toMillis()) !== -1
        }
    }

    const isNextTimeAvailable = (date: DateTime, unit: string, duration: number) => {
        if (date && disabledNoAvailableDate) {
            const nextDate: DateTime = addTimeToDate(date.setZone(_timeZone), unit, duration)
            const nextEpoch: number = nextDate.toMillis()
            return availableData.indexOf(nextEpoch) !== -1
        }
    }

    const isPreviousTimeAvailable = (date: DateTime, unit: string, duration: number) => {
        if (date) {
            const nextDate: DateTime = subtractTimeToDate(date.setZone(_timeZone), unit, duration)
            const nextEpoch: number = nextDate.toMillis()
            return availableData.indexOf(nextEpoch) !== -1
        }
    }
    const disabledStepForward = allowFuture
        ? false
        : maxDateTime && addTimeToDate(value, 'minutes', minutesStep) > maxDateTime

    const disabledLongTimeForward = allowFuture
        ? false
        : maxDateTime && addTimeToDate(value, longTimeStep.unit, longTimeStep.number) > maxDateTime

    const disabledStepBack = allowPast
        ? false
        : minDateTime && subtractTimeToDate(value, 'minutes', minutesStep) < minDateTime

    const disabledLongTimeBack = allowPast
        ? false
        : minDateTime && subtractTimeToDate(value, longTimeStep.unit, longTimeStep.number) < minDateTime

    const disableStepForwardByNoAvailableData = disabledNoAvailableDate ? !isNextTimeAvailable(value, 'minutes', minutesStep) : false
    const disableLongTimeForwardByNoAvailableData = disabledNoAvailableDate ? !isNextTimeAvailable(value, longTimeStep.unit, longTimeStep.number) : false
    const disableStepBackByNoAvailableData = disabledNoAvailableDate ? !isPreviousTimeAvailable(value, 'minutes', minutesStep) : false
    const disableLongTimeBackByNoAvailableData = disabledNoAvailableDate ? !isPreviousTimeAvailable(value, longTimeStep.unit, longTimeStep.number) : false

    const renderInput = (params: TextFieldProps) => {
        let timeParams = { ...params }
        let dateParams = { ...params }

        if (timeParams?.inputProps?.value) {
            timeParams.inputProps = {
                ...params.inputProps,
                value: value.setZone(_timeZone).toFormat(_config.date_format.time),
            }
        }
        if (dateParams?.inputProps?.value) {
            dateParams.inputProps = {
                ...params.inputProps,
                value: value.setZone(_timeZone).toFormat(_config.date_format.date),
            }
        }

        return <ClockInputRender timeParams={timeParams} dateParams={dateParams} />
    }

    const closeListEvents = () => {
        if(_lListEventsFlag) {
            dispatch(toggleListEvents(false))
        }
    }

    const DatetimePicker = () => {
        if (!allowPast) {
            return (
                <StyledMobileDateTimePicker
                    value={value}
                    ampm={false}
                    minDateTime={minDateTime}
                    minDate={minDateTime}
                    minTime={minDateTime}
                    showToolbar={false}
                    onAccept={(date: any) => handleAccept(date)}
                    onChange={(date: any) => handleChange(date)}
                    disabled={isDisabled || _fetchingData}
                    minutesStep={minutesStep}
                    DialogProps={{ style: { color: 'magenta' } }}
                    renderInput={(params: any) => renderInput(params)}
                    disableIgnoringDatePartForTimeValidation={true}
                    dayOfWeekFormatter={day => {
                        return i18n.resolvedLanguage?.includes('zh')
                            ? day.slice(1, 2).toUpperCase()
                            : day.slice(0, 1).toUpperCase()
                    }}
                />
            )
        } else if (!allowFuture) {
            return (
                <StyledMobileDateTimePicker
                    value={value}
                    ampm={false}
                    maxDateTime={maxDateTime}
                    maxDate={maxDateTime}
                    maxTime={maxDateTime}
                    minDateTime={DateTime.fromMillis(Number(availableMaxAndMinTimesWithData.from), { zone: _timeZone })}
                    minDate={DateTime.fromMillis(Number(availableMaxAndMinTimesWithData.from), { zone: _timeZone })}
                    minTime={DateTime.fromMillis(Number(availableMaxAndMinTimesWithData.from), { zone: _timeZone })}
                    showToolbar={false}
                    onAccept={(date: any) => handleAccept(date)}
                    onChange={(date: any) => handleChange(date)}
                    onMonthChange={(date: any) => {
                        dispatch(loadMonthAvailableData({ month: date.month, year: date.year }))
                    }}
                    shouldDisableDate={(day: any) => {
                        const date: string = day.toISODate() || ''
                        const result = availableDatesAndTimes[date]
                        return !result
                    }}
                    shouldDisableTime={(timeValue: number, clockType: ClockPickerView) => {
                        if(comeFromCancel) {
                            return false
                        } else {
                            let result = true
                            if (value && Object.keys(availableDatesAndTimes).length) {
                                const date = value.toISODate() || ''
                                const hour = value.get('hour')
                                const availableTimes = availableDatesAndTimes[date]
                                if (clockType === 'hours') {
                                    result = !!availableTimes[timeValue]
                                } else if (clockType === 'minutes') {
                                    if (availableTimes[hour]) {
                                        result = availableTimes[hour].includes(timeValue)
                                    }
                                }
                            }
                            return !result
                        }
                    }}
                    disabled={isDisabled || _fetchingData}
                    minutesStep={minutesStep}
                    DialogProps={{ style: { color: 'magenta' } }}
                    renderInput={(params: any) => renderInput(params)}
                    disableIgnoringDatePartForTimeValidation={true}
                    dayOfWeekFormatter={day => {
                        return i18n.resolvedLanguage?.includes('zh')
                            ? day.slice(1, 2).toUpperCase()
                            : day.slice(0, 1).toUpperCase()
                    }}
                />
            )
        } else {
            return (
                <StyledMobileDateTimePicker
                    value={value}
                    ampm={false}
                    maxDateTime={maxDateTime}
                    maxDate={maxDateTime}
                    maxTime={maxDateTime}
                    showToolbar={false}
                    onAccept={(date: any) => handleAccept(date)}
                    onChange={(date: any) => handleChange(date)}
                    disabled={isDisabled || _fetchingData}
                    minutesStep={minutesStep}
                    DialogProps={{ style: { color: 'magenta' } }}
                    renderInput={(params: any) => renderInput(params)}
                    disableIgnoringDatePartForTimeValidation={true}
                    dayOfWeekFormatter={day => {
                        return i18n.resolvedLanguage?.includes('zh')
                            ? day.slice(1, 2).toUpperCase()
                            : day.slice(0, 1).toUpperCase()
                    }}
                />
            )
        }
    }

    return (
        <ClockWrapper>
            {showArrowProgress && (
                <>
                    <ClockStyledIconButton
                        disabled={disabledLongTimeBack || disableLongTimeBackByNoAvailableData || _fetchingData}
                        onClick={goLongTimeBack}>
                        <FirstPage />
                    </ClockStyledIconButton>
                    <ClockStyledIconButton
                        disabled={disabledStepBack || disableStepBackByNoAvailableData || _fetchingData}
                        onClick={goStepBack}>
                        <ChevronLeft />
                    </ClockStyledIconButton>
                </>
            )}
            <LocalizationProvider
                localeText={{ cancelButtonLabel: t('clockButtons.cancel'), okButtonLabel: t('clockButtons.ok') }}
                dateAdapter={AdapterLuxon}
                adapterLocale={i18n.language}>
                {DatetimePicker()}
            </LocalizationProvider>
            {showArrowProgress && (
                <>
                    <ClockStyledIconButton
                        disabled={disabledStepForward || disableStepForwardByNoAvailableData || _fetchingData}
                        onClick={goStepForward}>
                        <ChevronRight />
                    </ClockStyledIconButton>
                    <ClockStyledIconButton
                        disabled={disabledLongTimeForward || disableLongTimeForwardByNoAvailableData || _fetchingData}
                        onClick={goLongTimeForward}>
                        <LastPage />
                    </ClockStyledIconButton>
                </>
            )}
        </ClockWrapper>
    )
}

export default Clock
