import React  from 'react'
import { Autocomplete, Grid} from '@mui/material'
import {
    QmDialogPickersWrapper,
    QmDialogHorizonTab,
    QmDialogTabs,
    QmDialogTabsHorizonBox,
    QmDialogFormControlStyled,
    QmDialogSelectorWrapper,
    QmDialogAutocompleteInputLabelStyled,
} from './qualityManagerDialogStyles'
import QualityManagerDialogSelector from './qualityManagerDialogSelector'
import { QualityManagerDatePicker } from './qualityManagerDialogDatePicker'
import { QualityManagerOneDayDatePicker } from './qualityManagerDialogOneDayDatePicker'
import { useTranslation } from 'react-i18next'

const QualityManagerDialogPickers: React.FC<IQualityManagerDialogPickersProps> = ({
    options,
    viewMode,
    horizon,
    horizons,
    isLoading,
    visualisation,
    outputSelected,
    stationSelected,
    sourceSelected,
    intervalSelected,
    filterSelected,
    numberItemsToShow,
    disableFutureNavigation,
    disabled,
    oneDayDate,
    fromDate,
    targetDate,
    handleVisualisationChange,
    handleOutputChange,
    handleStationChange,
    handleSourceChange,
    handleIntervalChange,
    handleFilterChange,
    handleItemsToShowChange,
    handleTabsChange,
    loadPreviousWeek,
    loadPreviousDay,
    loadNextWeek,
    loadNextDay,
    loadLastPage,
    handleFromDatePickerChange,
    handleTargetDatePickerChange,
    loadPreviousWeekOneDay,
    loadPreviousOneDay,
    loadNextWeekOneDay,
    loadNextOneDay,
    loadLastPageOneDay,
    handleOneDayDatePickerChange,
    allStations,
}: IQualityManagerDialogPickersProps) => {
    const { t } = useTranslation()

    const qmOutputOptions = visualisation !== 'simulatedVsReal' ?
        options.qm_output.filter((option: string) => option !== 'delayTimeQmSubpath') : options.qm_output

    return (
        <QmDialogPickersWrapper container direction='column' justifyContent='center' alignItems='stretch'>
            <Grid item>
                <Grid
                    container
                    justifyContent='flex-start'
                    alignContent='flex-start'
                    alignItems='center'
                    direction='row'
                    spacing={4}>
                    <QualityManagerDialogSelector
                        inputLabel={t('qualityManager.dataVisualisation')}
                        valueSelected={visualisation}
                        valueSelectorOptions={options.types}
                        changeValueSelected={handleVisualisationChange}
                        isLoading={isLoading}
                    />
                    {(visualisation === 'percentageMissingData' || visualisation === 'patternsVsCleanData') && (
                        <QualityManagerDialogSelector
                            inputLabel={t('qualityManager.output')}
                            valueSelected={outputSelected}
                            valueSelectorOptions={options.variables}
                            changeValueSelected={handleOutputChange}
                            isLoading={isLoading}
                        />
                    )}
                    {visualisation === 'patternsVsCleanData' && (
                        <QmDialogSelectorWrapper item>
                            <QmDialogFormControlStyled>
                                <Autocomplete
                                    value={stationSelected}
                                    renderInput={(params) =>
                                        <QmDialogAutocompleteInputLabelStyled
                                            {...params}
                                            variant='standard'
                                            label={t('qualityManager.aggregation')}
                                        />
                                    }
                                    onChange={(event, value, reason) => handleStationChange(event, value, reason)}
                                    disabled={isLoading}
                                    options={allStations}
                                />
                            </QmDialogFormControlStyled>
                        </QmDialogSelectorWrapper>
                    )}
                    {(visualisation === 'realVsPredicted' || visualisation === 'simulatedVsReal') && viewMode !== 3 && (
                        <QualityManagerDialogSelector
                            inputLabel={t('qualityManager.output')}
                            valueSelected={outputSelected}
                            valueSelectorOptions={qmOutputOptions}
                            changeValueSelected={handleOutputChange}
                            isLoading={isLoading}
                        />
                    )}
                    {visualisation === 'percentageMissingData' && (
                        <QualityManagerDialogSelector
                            inputLabel={t('qualityManager.source')}
                            valueSelected={sourceSelected}
                            valueSelectorOptions={options.source}
                            changeValueSelected={handleSourceChange}
                            isLoading={isLoading}
                        />
                    )}
                    {visualisation === 'percentageMissingData' && (
                        <QualityManagerDialogSelector
                            inputLabel={t('qualityManager.interval')}
                            valueSelected={intervalSelected}
                            valueSelectorOptions={options.interval}
                            changeValueSelected={handleIntervalChange}
                            isLoading={isLoading}
                        />
                    )}
                    {visualisation !== 'patternsVsCleanData' &&
                        visualisation !== 'patternsVariability' &&
                        viewMode !== 3 && (
                            <QualityManagerDatePicker
                                disabled={disabled}
                                disableFutureNavigation={disableFutureNavigation}
                                fromDate={fromDate}
                                targetDate={targetDate}
                                onLoadPreviousWeek={loadPreviousWeek}
                                onLoadPreviousDay={loadPreviousDay}
                                onLoadNextWeek={loadNextWeek}
                                onLoadNextDay={loadNextDay}
                                onLoadLastPage={loadLastPage}
                                handleFromDatePickerChange={handleFromDatePickerChange}
                                handleTargetDatePickerChange={handleTargetDatePickerChange}
                            />
                        )}
                    {visualisation === 'patternsVsCleanData' && (
                        <QualityManagerOneDayDatePicker
                            disabled={disabled}
                            disableFutureNavigation={disableFutureNavigation}
                            fromDate={oneDayDate}
                            onLoadPreviousWeek={loadPreviousWeekOneDay}
                            onLoadPreviousDay={loadPreviousOneDay}
                            onLoadNextWeek={loadNextWeekOneDay}
                            onLoadNextDay={loadNextOneDay}
                            onLoadLastPage={loadLastPageOneDay}
                            handleFromDatePickerChange={handleOneDayDatePickerChange}
                        />
                    )}
                    {visualisation === 'patternsVariability' && (
                        <QualityManagerDialogSelector
                            inputLabel={t('qualityManager.valuesToShow')}
                            valueSelected={numberItemsToShow.toString()}
                            valueSelectorOptions={[]}
                            changeValueSelected={handleItemsToShowChange}
                            isLoading={isLoading}
                            nValues={100}
                            renderValue={() => <span>{numberItemsToShow}</span>}
                        />
                    )}
                </Grid>
            </Grid>
            {(viewMode === 0 || viewMode === 2) && visualisation !== 'patternsVsCleanData' && (
                <QmDialogTabsHorizonBox sx={isLoading ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                    <QmDialogTabs
                        value={horizon}
                        onChange={handleTabsChange}
                        aria-label='view mode tabs'
                        textColor='inherit'
                        TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}>
                        {horizons.map(({ id, value }) => {
                            return <QmDialogHorizonTab label={value.toString()} value={id} key={id} />
                        })}
                    </QmDialogTabs>
                </QmDialogTabsHorizonBox>
            )}
        </QmDialogPickersWrapper>
    )
}

export default QualityManagerDialogPickers
