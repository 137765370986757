import styled from '@emotion/styled'
import { Box, ButtonGroup, Button } from '@mui/material'
import { RED_LIVE, WHITE } from '../../theme'

export const NavigatorCanvas = styled(Box)`
    height: 70px;
    padding: 15px;
    margin-right: auto;
`

export const NavigatorButtonGroup = styled(ButtonGroup)`
  transition: 0.8s;
`

export const NavigatorButton = styled(Button)`
  &:hover {
    background-color: ${RED_LIVE};
    color: ${WHITE};
  }
  &.MuiButtonGroup-grouped {
    border: none;
  }
`

