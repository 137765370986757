import * as React from 'react'
import {
    CardContent,
    IconButton,
    ListItem,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
    CardEventsHeaderInfoModalStyled,
    CardEventsInfoModalStyled,
    EventsInfoModalFormControlStyled,
    EventsInfoModalSlideStyled,
    EventsInfoModalTitle,
} from './listEventsInfoModalStyles'
import { NoDataMessage } from '../../containers/manageContainer/components/kpi/kpisComparison/kpiComparisonDialog/KpiComparisonDialogStyles'
import { TABLE_ROW, WHITE } from '../../theme'

export const ListEventsDetectedInfoModal: React.FC<ListEventsDetectedInfoModalProps> = ({ detectedIncidents, toggleEventsDetectedDialog }) => {
    const { t } = useTranslation()

    const handleClose = () => {
        toggleEventsDetectedDialog(false)
    }

    const cardTitle = <EventsInfoModalTitle>{t('listEvents.eventsDetected')}</EventsInfoModalTitle>

    return (
        <EventsInfoModalSlideStyled direction='left' in={true} mountOnEnter unmountOnExit>
            <CardEventsInfoModalStyled>
                <CardEventsHeaderInfoModalStyled
                    action={
                        <IconButton aria-label='settings' onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title={cardTitle}
                />
                <CardContent>
                    <EventsInfoModalFormControlStyled key='event-log-form' variant='standard'>
                        {detectedIncidents.features.length > 0 ?
                            detectedIncidents.features
                                .map((incident: any, index: number) => {
                                    const description = incident.properties.description.split('<br>')
                                    const incidentInfo = description[2]
                                    const grayBackground = index % 2 === 0
                                     return (
                                         <ListItem
                                             key={'list_detected_events' + index}
                                             style={{
                                                 backgroundColor: !grayBackground ? WHITE : TABLE_ROW,
                                                 borderRadius: '8px'
                                            }}
                                         >
                                             {incidentInfo}
                                         </ListItem>
                                     )
                                }
                            ) : (
                                <NoDataMessage align='center'>
                                    {t('listEvents.noEventsDetected')}
                                </NoDataMessage>
                            )
                        }
                    </EventsInfoModalFormControlStyled>
                </CardContent>
            </CardEventsInfoModalStyled>
        </EventsInfoModalSlideStyled>
    )
}
