import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/store'
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson'

export const initialSelectedResponsePlanState: IResponsePlan = {
    id: -1,
    name: '',
    description: '',
    eid: '',
    events: [],
    actions: [],
    responsePlanId: 0,
    selected: false,
    in_use: false,
}

const initialResponsePlanListState: ISelectedEvaluationState = {
    responsePlan: initialSelectedResponsePlanState,
    events: null,
    actions: null,
    hoverAction: 0,
    removed: false,
}

const responsePlanListSlice = createSlice({
    name: 'responsePlanList',
    initialState: initialResponsePlanListState,
    reducers: {
        storeSelectedResponsePlan: (state, action: PayloadAction<IResponsePlan>): void => {
            state.responsePlan = action.payload
        },
        restoreSelectedResponsePlan: (state): void => {
            state.responsePlan = initialSelectedResponsePlanState
            state.events = initialResponsePlanListState.events
            state.actions = initialResponsePlanListState.actions
            state.removed = initialResponsePlanListState.removed
        },
        storeSelectedResponsePlanActions: (
            state,
            action: PayloadAction<FeatureCollection<Geometry, GeoJsonProperties>>
        ): void => {
            state.actions = action.payload
        },
        storeHoverResponsePlanAction: (state, action: PayloadAction<number>): void => {
            state.hoverAction = action.payload
        },
        restoreHoverResponsePlanAction: (state): void => {
            state.hoverAction = 0
        },
        setStaticResponsePlanListSelectorRemoved: (state, action: PayloadAction<any>): void => {
            state.removed = action.payload
        },
    },
})

export const {
    storeSelectedResponsePlan,
    storeSelectedResponsePlanActions,
    restoreSelectedResponsePlan,
    storeHoverResponsePlanAction,
    restoreHoverResponsePlanAction,
    setStaticResponsePlanListSelectorRemoved,
} = responsePlanListSlice.actions

export const selectSelectedResponsePlan = (state: RootState) => state.responsePlanList.responsePlan
export const selectSelectedResponsePlanActions = (state: RootState) => state.responsePlanList.actions
export const selectHoverResponsePlanAction = (state: RootState) => state.responsePlanList.hoverAction
export const selectRemovedResponsePlan = (state: RootState) => state.responsePlanList.removed

export default responsePlanListSlice.reducer
