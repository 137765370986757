import React, { useState } from 'react'
import {
    Box,
    Checkbox,
    InputAdornment,
    Typography,
} from '@mui/material'
import {
    GREEN,
    GREEN_DARK,
    GREEN_LIGHT,
    GREY,
    MUSTARD,
    MUSTARD_DARK,
    MUSTARD_DARKER,
    NAVY_BLUE,
    NEXT_BLUE,
    NEXT_DARK,
    OLIVE,
    OLIVE_ISH,
    OLIVE_LIGHT,
    PALE_PINK,
    PURPLE_ISH,
    RED_LIVE,
    RED_LIVE_DARK,
    RED_LIVE_DARKER,
    SMOKE,
} from '../../../../../theme'
import {
    DiversionRoutePercentageStyled,
    TrafficActionsDiversionContent,
    TrafficActionsDiversionSet,
    TrafficActionsDiversionWrapper,
} from './trafficActionsDiversionRouteStyles'
import { useTranslation } from 'react-i18next'

export const COLORS_DIVERSION: string[] = [
    MUSTARD,
    MUSTARD_DARK,
    MUSTARD_DARKER,
    RED_LIVE,
    RED_LIVE_DARK,
    RED_LIVE_DARKER,
    NEXT_BLUE,
    NEXT_DARK,
    NAVY_BLUE,
    OLIVE_LIGHT,
    OLIVE,
    OLIVE_ISH,
    GREEN_LIGHT,
    GREEN,
    GREEN_DARK,
    PALE_PINK,
    PURPLE_ISH,
    SMOKE,
    GREY,
]

const TrafficActionsDiversionRoute: React.FC<TrafficActionsDiversionRouteProps> = ({
    highlightDiversionRoute,
    clearDiversionRoute,
    handleDiversionRouteChecked,
    diversionRouteList,
    diversionRoutesSelected,
    maxValuePercentage,
    errorDiversionRouteId
}): any => {
    const { t } = useTranslation()
    const [diversionRoutePercentage, setDiversionRoutePercentage] = useState<any[]>([])


    const handleDiversionRoutesChange = (event: React.ChangeEvent<HTMLInputElement>, subpath: any, color: string) => {
        const id = Number.parseInt(event.target.value)
        const routeToCheck = diversionRouteList.find((diversionRoute: any) => diversionRoute.id === id)
        let drPercentage = diversionRoutePercentage
        if (!event.target.checked) {
            drPercentage = diversionRoutePercentage.filter((drPerc: any) => drPerc.subpathId !== subpath.id)
            setDiversionRoutePercentage(drPercentage)
        }
        handleDiversionRouteChecked(event.target.checked, subpath, routeToCheck, color, drPercentage)
    }

    const handleDiversionHighlightRoutes = (event: any, color: string) => {
        const id = Number.parseInt(event.target.value)
        const routeToHighlight = diversionRouteList.find((diversionRoute: any) => diversionRoute.id === id)
        highlightDiversionRoute(routeToHighlight, color)
    }

    const handlePercentageChange = (value: any, subpath: any, checked: boolean, color: string) => {
        const drPercentageFiltered = diversionRoutePercentage.filter((drPerc: any) => drPerc.subpathId !== subpath.id)
        const newDRPercentage = drPercentageFiltered.concat({ subpathId: subpath.id, value: value })
        setDiversionRoutePercentage(newDRPercentage)
        handleDiversionRouteChecked(checked, subpath, subpath, color, newDRPercentage)
    }

    return (
        <TrafficActionsDiversionContent>
            <TrafficActionsDiversionSet className='action-maker__title'>
                {t('manageActions.setDiversionRoute')}
            </TrafficActionsDiversionSet>
            {diversionRouteList.map((subpath: DiversionRoute, index: number) => {
                const _checked = !!diversionRoutesSelected.find((currentSubpath: any) => currentSubpath.id === subpath.id)
                const label = `${subpath.id} ${subpath?.name}`

                const drPercentage = diversionRoutePercentage.find((drPerc: any) => drPerc.subpathId === subpath.id)
                const errorDRPercentage = _checked && (drPercentage?.value === '' || !drPercentage?.value)
                const errorDRPercentageNumber = Number(drPercentage?.value) < 0 || Number(drPercentage?.value) > 100
                const errorMaxValuePercentage = drPercentage?.value > maxValuePercentage &&
                    errorDiversionRouteId.find((drId: any) => drId === subpath.id) !== undefined
                const errorMaxValuePercentageText = `${t('manageEvaluation.errorPercentageSum')} ${maxValuePercentage.toFixed(1)}`
                const errorPercentageNumberHelper = errorDRPercentageNumber ? t('manageEvaluation.errorPercentage') : errorMaxValuePercentage ? errorMaxValuePercentageText : ''
                const helperText = errorDRPercentage ? t('manageEvaluation.required') : errorPercentageNumberHelper

                return (
                    <TrafficActionsDiversionWrapper key={subpath.id}>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Box
                                style={{
                                    width: '30px',
                                    height: '3px',
                                    backgroundColor: COLORS_DIVERSION[index],
                                }}
                            />
                            <Checkbox
                                onMouseEnter={(event) =>handleDiversionHighlightRoutes(event, COLORS_DIVERSION[index])}
                                onMouseLeave={clearDiversionRoute}
                                checked={_checked}
                                onChange={(event) => handleDiversionRoutesChange(event, subpath, COLORS_DIVERSION[index])}
                                value={subpath.id}
                                tabIndex={index}
                                color='primary'
                            />
                            <Typography
                                style={{
                                    marginLeft: '5px',
                                    marginRight: '10px',
                                    marginTop: '2px',
                                }}>
                                {label}
                            </Typography>
                            <DiversionRoutePercentageStyled
                                id={`percentage_${subpath.id}`}
                                value={drPercentage?.value || ''}
                                disabled={!_checked}
                                onChange={(event) => handlePercentageChange(event.target.value, subpath, _checked, COLORS_DIVERSION[index] )}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                }}
                                variant={'standard'}
                                style={{
                                    width: '50px',
                                }}
                                error={errorDRPercentage || errorDRPercentageNumber || errorMaxValuePercentage}
                                helperText={helperText}
                                type={'number'}
                            />
                        </Box>
                    </TrafficActionsDiversionWrapper>
                )
            })}
        </TrafficActionsDiversionContent>
    )
}

export default TrafficActionsDiversionRoute
