import { configureStore, ThunkAction, Action, Middleware, AnyAction, Dispatch } from '@reduxjs/toolkit'
import { listenToSocketChanges } from '../sagas/liveDataSaga'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import patternReducer from '../features/pattern/store/patternSlice'
import stationSelectedReducer from '../features/stationDialog/store/stationSelectedSlice'
import qualityManagerReducer from '../features/qualityManagerDialog/store/qualityManagerDialogSlice'
import mapboxReducer from '../features/mapbox/mapboxSlice'
import coreReducer from '../features/core/coreSlice'
import rootSaga from './rootSaga'
import realDataReducer from '../features/core/realData/realDataSlice'
import analyticalDataReducer from '../features/core/analyticalData/analyticalDataSlice'
import simulatedDataReducer from '../features/core/simulatedlData/simulatedDataSlice'
import riskPredictionDataReducer from '../features/core/riskPredictionData/riskPredictionDataSlice'
import viewReducer from '../features/viewSettings/viewSettingsSlice'
import horizonReducer from '../features/horizonSlider/horizonSilderSlice'
import systemStatusReducer from '../features/systemStatus/SystemStatusSlice'
import authReducer from '../features/auth/authSlice'
import dashboardReducer from '../containers/dashboardContainer/DashboardContainerSlice'
import ManageContainerStore from '../containers/manageContainer/ManageContainerStore'
import AnalyzeContainerStore from '../containers/analyzeContainer/AnalyzeContainerstore'
import evaluationsReducer from '../features/evaluationsSelector/store/evaluationsSlice'
import evaluationsLandUseReducer from '../features/evaluationsSelector/store/evaluationsLandUseSlice'
import evaluationsManageReducer from '../features/evaluationsSelector/store/evaluationsManageSlice'
import eventsReducer from '../features/eventsToolbar/store/eventsSlice'
import baselineLandUseReducer from '../features/baselineLandUse/baselineLandUseSlice'
import evaluationSimulationDataReducer from '../features/core/simulationEvaluationData/evaluationSimulationDataSlice'
import mapHorizonSelectedReducer from '../features/mapHorizonSelector/store/mapHorizonSelectedSlice'
import searchFeatureReducer from '../features/search/store/searchFeatureSlice'
import secondarySaga from './secondarySaga'
import actionsReducer from '../features/actionsToolbar/store/actionsSlice'
import speedRecommendationDataReducer from '../features/core/speedRecommendationData/speedRecommendationDataSlice'
import responsePlanReducer from '../features/responsePlanToolbar/store/responsePlanSlice'
import responsePlanManageLiveReducer from '../features/evaluationsSelector/newManageLiveEvaluationDialog/store/responsePlanManageLiveSlice'
import evaluationLiveReducer from '../features/evaluationsToolbar/store/evaluationLiveSlice'
import trafficActionReducer from '../containers/manageContainer/components/trafficActions/store/trafficActionSlice'
import eventLogListReducer from '../features/listEventsModal/store/listEventsSlice'
import dailyReportInfoReducer from '../features/dailyReportModal/store/dailyReportSlice'
import responsePlanListReducer from '../containers/manageContainer/components/responsePlanListSelector/responsePlanListSelectorSlice'

let mode: 'development' | 'production' | 'test' = process.env.NODE_ENV || 'production'
if (mode === 'development') {
    console.log('Launching development environment')
}

let sagaMiddleware = createSagaMiddleware()
let wsSagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger({
    collapsed: true,
}) as Middleware<any, any, Dispatch<AnyAction>>

const store = configureStore({
    reducer: {
        core: coreReducer,
        pattern: patternReducer,
        mapbox: mapboxReducer,
        stationsSelectedSlice: stationSelectedReducer,
        realData: realDataReducer,
        analyticalData: analyticalDataReducer,
        simulatedData: simulatedDataReducer,
        riskPredictionData: riskPredictionDataReducer,
        speedRecommendationData: speedRecommendationDataReducer,
        analyzeContainer: AnalyzeContainerStore,
        manageContainer: ManageContainerStore,
        qualityManager: qualityManagerReducer,
        view: viewReducer,
        system: systemStatusReducer,
        horizon: horizonReducer,
        auth: authReducer,
        evaluations: evaluationsReducer,
        evaluationsLandUse: evaluationsLandUseReducer,
        evaluationsManage: evaluationsManageReducer,
        events: eventsReducer,
        dashboard: dashboardReducer,
        baselineLandUse: baselineLandUseReducer,
        evaluationSimulationData: evaluationSimulationDataReducer,
        mapHorizonSelected: mapHorizonSelectedReducer,
        searchFeature: searchFeatureReducer,
        actions: actionsReducer,
        responsePlan: responsePlanReducer,
        responsePlanManageLive: responsePlanManageLiveReducer,
        responsePlanList: responsePlanListReducer,
        evaluationLive: evaluationLiveReducer,
        trafficAction: trafficActionReducer,
        eventsLogList: eventLogListReducer,
        dailyReportInfo: dailyReportInfoReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            thunk: false,
            immutableCheck: false,
            serializableCheck: false,
        }).concat(
            mode === 'production'
                ? [sagaMiddleware, wsSagaMiddleware]
                : [sagaMiddleware, wsSagaMiddleware, loggerMiddleware]
        ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

wsSagaMiddleware.run(listenToSocketChanges)
sagaMiddleware.run(rootSaga)
sagaMiddleware.run(secondarySaga)

export default store
