import { DateTime } from 'luxon'
import { addTimeToDate, subtractTimeToDate } from '../../helpers/DateTimeHelper'
import { converterToImperialSystem } from '../../helpers/unitsHelper'

const getNullData = (epoch: number, cleanerInterval: number, timeZone: string): lineDataChart[] => {
    const minTime = subtractTimeToDate(DateTime.fromMillis(epoch, { zone: timeZone }), 'hour', 6)
    const maxTime = addTimeToDate(DateTime.fromMillis(epoch, { zone: timeZone }), 'hour', 1)
    const nullSeries: lineDataChart[] = []
    for (let i = minTime; i <= maxTime; i = addTimeToDate(i, 'minutes', cleanerInterval)) {
        nullSeries.push({ x: i.toMillis(), y: null })
    }
    return nullSeries
}

const addNullRealToPredictionData = (
    data: realDataType[],
    epoch: number,
    predictionInterval: number,
    timeZone: string
): lineDataChart[] => {
    const minTime = subtractTimeToDate(DateTime.fromMillis(epoch, { zone: timeZone }), 'hour', 6)
    const maxTime = DateTime.fromMillis(epoch, { zone: timeZone })
    const nullSeries: lineDataChart[] = []
    for (let i = minTime; i <= maxTime; i = addTimeToDate(i, 'minutes', predictionInterval)) {
        let dataFound = null
        if (i.equals(maxTime)) {
            dataFound = data.find(element => element.time === i.toMillis())
        }
        nullSeries.push({ x: i.toMillis(), y: dataFound ? dataFound.value : null })
    }
    return nullSeries
}

export const prepareRealDataToChart = (
    data: realDataType[],
    epoch: number,
    cleanerInterval: number,
    timeZone: string,
    unitType?: string
): lineDataChart[] => {
    let realData = getNullData(epoch, cleanerInterval, timeZone)
    if (data.length === 0) {
        return realData
    } else {
        return realData.map(timeData => {
            const found = data.find(element => element.time === timeData.x)
            const foundValue = found ? found.value : null
            const value = unitType ? converterToImperialSystem(unitType, foundValue) : foundValue
            return {
                x: timeData.x,
                y: value,
            }
        })
    }
}
export const preparePredictionDataToShowOnChart = (
    data: predictionDataType[],
    realData: realDataType[],
    epoch: number,
    cleanerInterval: number,
    timeZone: string,
    unitType?: string
): lineDataChart[] => {
    const dataFound = data.find(element => element.value !== null)

    if (!dataFound) {
        return getNullData(epoch, cleanerInterval, timeZone)
    } else {
        const timeData: DateTime = DateTime.fromMillis(epoch, { zone: timeZone })
        const predictionData: lineDataChart[] = data.map(({ horizon, value }) => {
            return {
                x: addTimeToDate(timeData, 'minutes', Number(horizon)).toMillis(),
                y: Number(value),
            }
        })
        const series = addNullRealToPredictionData(realData, epoch, cleanerInterval, timeZone).concat(predictionData)
        return series.map(({ x, y }: lineDataChart) => {
            return {
                x,
                y: unitType ? converterToImperialSystem(unitType, y) : y,
            }
        })
    }
}

export const preparePredictionDataToHideChart = (
    data: predictionDataType[],
    realData: realDataType[],
    epoch: number,
    cleanerInterval: number,
    timeZone: string,
    unitType?: string
): lineDataChart[] => {
    const dataFound = data.find(element => element.value !== null)

    let predictionNullData = getNullData(epoch, cleanerInterval, timeZone)
    if (!dataFound) {
        return predictionNullData
    } else {
        const timeData: DateTime = DateTime.fromMillis(epoch, { zone: timeZone })
        const predictionData: lineDataChart[] = data.map(({ horizon, value }) => {
            const displayValue = unitType ? converterToImperialSystem(unitType, value) : value
            return {
                x: addTimeToDate(timeData, 'minutes', Number(horizon)).toMillis(),
                y: displayValue,
            }
        })
        return predictionNullData.map(nullData => {
            const found = predictionData.find(element => element.x === nullData.x)
            return {
                x: nullData.x,
                y: found ? found.y : null,
            }
        })
    }
}

export const preparePatternMinDataToChart = (
    data: dataPatternType[],
    epoch: number,
    cleanerInterval: number,
    timeZone: string,
    unitType?: string
): lineDataChart[] => {
    if (data.length === 0) {
        return getNullData(epoch, cleanerInterval, timeZone)
    } else {
        const minPatternData = getNullData(epoch, cleanerInterval, timeZone)

        data.forEach(timeData => {
            const indexFound = minPatternData.findIndex((point: lineDataChart) => point.x === timeData.time)
            if (indexFound !== -1) {
                const displayValue = unitType
                    ? converterToImperialSystem(unitType, timeData.min_value)
                    : timeData.min_value
                minPatternData[indexFound] = {
                    x: timeData.time,
                    y: displayValue,
                }
            }
        })

        return minPatternData
    }
}

export const preparePatternMaxDataToChart = (
    data: dataPatternType[],
    epoch: number,
    cleanerInterval: number,
    timeZone: string,
    unitType?: string
): lineDataChart[] => {
    if (data.length === 0) {
        return getNullData(epoch, cleanerInterval, timeZone)
    } else {
        const maxPatternData = getNullData(epoch, cleanerInterval, timeZone)

        data.forEach(timeData => {
            const indexFound = maxPatternData.findIndex((point: lineDataChart) => point.x === timeData.time)
            if (indexFound !== -1) {
                const displayValue = unitType
                    ? converterToImperialSystem(unitType, timeData.max_value)
                    : timeData.max_value
                maxPatternData[indexFound] = {
                    x: timeData.time,
                    y: displayValue,
                }
            }
        })

        return maxPatternData
    }
}

export const getMarks = (step: number, numberOfHorizon: number) => {
    const marks = []
    for (let i = 1; i <= numberOfHorizon; i++) {
        marks.push(i * step)
    }

    return marks
}

export const getTypePredictionSource = (typeSource: 'stations' |'sections', viewMode: IViewMode): string => {
    return viewMode.sources[typeSource].future
}
