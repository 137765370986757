import {
    deleteRequestAPIById,
    getRequestAPI,
    postRequestAPI,
    updateRequestAPIById,
} from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export async function getManageResponsePlan(epoch?: number): Promise<AxiosResponse<IResponsePlan>> {
    if (epoch) {
        return getRequestAPI(`/api_dm/response_plans?epoch=${epoch}`, { cache: false })
    } else {
        return getRequestAPI(`/api_dm/response_plans`, { cache: false })
    }
}

export async function createManageResponsePlan(responsePlan: ICreateResponsePlan): Promise<AxiosResponse<any>> {
    return await postRequestAPI(`api_dm/response_plans`, responsePlan)
}

export async function deleteManageResponsePlan(responsePlan: IResponsePlan): Promise<AxiosResponse<any>> {
    return await deleteRequestAPIById(`api_dm/response_plans`, responsePlan)
}

export async function updateManageResponsePlan(responsePlan: IResponsePlan): Promise<AxiosResponse<any>> {
    return await updateRequestAPIById(`api_dm/response_plans`, responsePlan)
}
