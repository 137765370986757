import React, { useState, useEffect } from 'react'
import { selectStatus, selectConfig } from '../../features/core/coreSlice'
import { GridLoader } from 'react-spinners'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { ContainerWrapper } from '../../components/containerWrapper/containerWrapper'
import { ToolbarContainer } from '../../components/toolbarComponent/toolbarComponentStyles'
import { useAppSelector } from '../../app/hooks'
import { Grid } from '@mui/material'


const LoaderContainer: React.FC = () => {
    const appStatus = useAppSelector(selectStatus)
    const moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const [loading, setLoading] = useState<boolean>(true)
    const navigate: NavigateFunction = useNavigate()

    useEffect(() => {
        if (moduleConfig.modules.length > 0) {
            const moduleToGoFirst = moduleConfig.modules.find((module: IModule) => module.openFirst)
            const activeModule: string = moduleToGoFirst ? moduleToGoFirst.url : moduleConfig.modules[0].url
            if (appStatus === 'idle') {
                navigate(activeModule)
            }
        } else {
            setLoading(false)
        }
    }, [appStatus, moduleConfig, navigate])


    return (
        <ContainerWrapper>
            {loading ? (
                <Grid container direction='column' justifyContent='center' alignContent='center' alignItems='center'>
                    <Grid item>
                        <GridLoader size={26} margin={7} color='#E9A59D' />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <ToolbarContainer/>
                </>
            )}
        </ContainerWrapper>
    )
}

export default LoaderContainer
