import { NAVY_BLUE, OLIVE_ISH, RED_LIVE, RED_LIVE_OPAQUE, WHITE } from '../../theme'
import { DateTime } from 'luxon'
import store, { RootState } from '../../app/store'
import { t } from 'i18next'

export const stationDialogChartBothPredictionOptions = (
    epoch: number,
    timeZone: string,
    tooltip: ((options: any) => any) | ((options: any) => any)[] | undefined,
    titleYAxis: string | undefined,
    itemsLegend?: string[]
): ApexCharts.ApexOptions => {
    const _state: RootState = store.getState()
    const _config: IModuleConfig = _state.core.module_config
    return {
        annotations: {
            xaxis: [
                {
                    x: epoch,
                    strokeDashArray: 15,
                    borderColor: RED_LIVE_OPAQUE,
                    borderWidth: 5,
                },
            ],
        },
        chart: {
            height: 350,
            animations: {
                speed: 500,
                enabled: true,
                easing: 'easeinout',
                dynamicAnimation: {
                    enabled: false,
                }
            },
            type: 'rangeArea',
            toolbar: {
                show: false,
            },
            redrawOnParentResize: true,
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: [0.24, 1, 1, 1, 1, 0, 0],
        },
        stroke: {
            curve: 'straight',
            width: [1, 1, 2, 2, 2],
            colors: [RED_LIVE, RED_LIVE, OLIVE_ISH, RED_LIVE, NAVY_BLUE],
        },
        legend: {
            show: true,
            onItemClick: {
                toggleDataSeries: false,
            },
            position: 'bottom',
            markers: {
                width: 0,
            },
            customLegendItems: itemsLegend,
        },
        markers: {
            size: [0.000001, 0.000001, 3, 3, 3, 0.000001, 0.000001],
            strokeColors: [RED_LIVE, RED_LIVE, OLIVE_ISH, RED_LIVE, NAVY_BLUE],
            colors: [WHITE, WHITE, WHITE,WHITE, NAVY_BLUE],
            hover: {
                sizeOffset: 0,
            },
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            position: 'front',
        },
        tooltip: {
            custom: tooltip,
            // followCursor: true,
            // marker: {
            //     show: true,
            // },
        },
        xaxis: {
            labels: {
                formatter: function (val) {
                    return DateTime.fromMillis(Number(val), { zone: timeZone }).toFormat(_config.date_format.time)
                },
                hideOverlappingLabels: true,
            },
            title: {
                text: t('stationDialog.time'),
            },
            type: 'numeric',
            tickAmount: 14,
        },
        yaxis: {
            min: 0,
            labels: {
                formatter: function (val) {
                    return Number(val).toFixed(0)
                },
            },
            title: {
                text: t(titleYAxis),
            },
        },
    }
}
