import { call, put, takeLatest } from 'redux-saga/effects'
import { loadManageLiveResponsePlan, setResponsePlanLives } from './responsePlanManageLiveSlice'
import { getManageResponsePlan } from '../../../../services/responsePlans/responsePlansService'

function* fetchManageLiveResponsePlan(): any {
    const responsePlanLives: IResponsePlan[] = yield call(getManageResponsePlan)
    yield put(setResponsePlanLives(responsePlanLives))
}

function* responsePlanManageLiveSaga(): any {
    yield takeLatest(loadManageLiveResponsePlan, fetchManageLiveResponsePlan)
}

export default responsePlanManageLiveSaga
