import {
    Button,
    Card,
    CardHeader, Divider,
    FormControl,
    TextField,
    Typography,
} from '@mui/material'
import { LIGHT_GREY, NAVY_BLUE, NO_DATA } from '../../theme'
import styled from '@emotion/styled'

export const DailyReportInfoModalClockWrapper = styled.div`
  margin: 0 auto;
`

export const DailyReportInfoModalDescriptionWrapper = styled.div`
  margin-top: 12px;
`

export const DailyReportInfoModalLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const DailyReportInfoModalClockDivider = styled(Divider)`
  background-color: ${LIGHT_GREY};
  width: 100%;
  margin-top: 12px;
`

export const DailyReportInfoModalFormControlStyled = styled(FormControl)`
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
`
export const DailyReportInfoModalDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  
  &:hover {
    opacity: 0.85;
  }
`

export const DailyReportInfoModalDialogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 24px;
`

export const DailyReportInfoModalTitle = styled(Typography)`
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
  font-size: 24px;
  font-weight: bold;
  color: ${NAVY_BLUE};
`

export const DailyReportInfoModalDescription = styled(Typography)`
  margin: 12px 0 4px 0;
  display: flex;
  flex-direction: column;
`

export const DailyReportInfoModalListStation = styled.li`
  margin: 4px 0 4px 12px;
`

export const CardDailyReportInfoModalStyled = styled(Card)`
  padding: 8px 16px;
  position: absolute;
  min-width: 100px;
  right: 8px;
  top: 8px;
  margin: 70px 0 0 0;
  z-index: 1051;
`
export const CardDailyReportHeaderInfoModalStyled = styled(CardHeader)`
  border-bottom: 1px solid ${NO_DATA};
  span {
    font-size: 24px;
    font-weight: bold;
  }
`
export const ClockInputDailyReportRenderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  cursor: pointer;
`

export const ClockDateDailyReportInput = styled(TextField)`
  input {
    text-align: center;
    padding: 0;
    font-size: 21px;
    cursor: pointer;
  }
`
