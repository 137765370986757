import React, { useEffect, useState } from 'react'

import {
    Alert,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    IconButton,
    Radio,
    Slide,
    Snackbar,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import SearchTermComponent from '../../../../components/searchTermComponent/SearchTermComponent'
import {
    TableHeadStyled,
    TableCellHeaderStyled,
    TableCellHeaderTypography,
    TableRowContent,
    TableCellContentStyled,
} from '../../../../components/tableStyles/tabletyles'

import {
    deleteResponsePlan,
    setOpenResponsePlanCreateDialog,
    setResponsePlanCreating,
    setResponsePlanSelected,
} from '../../../../features/responsePlanToolbar/store/responsePlanSlice'
import CustomDialog from '../../../../components/customDialog/customDialog'
import {
    SearchTermWrapperEvaluationDialog,
    ResponsePlanDialogButtonsWrapper,
    ResponsePlanDialogButton,
    ResponsePlanDialogButtonCreate,
} from './responsePlanSelectorDialogStyles'
import { t } from 'i18next'
import { PulseLoader } from 'react-spinners'
import { TransitionProps } from '@mui/material/transitions'
import { loadManageLiveResponsePlan } from '../../../../features/evaluationsSelector/newManageLiveEvaluationDialog/store/responsePlanManageLiveSlice'
import {
    selectRemovedResponsePlan,
    setStaticResponsePlanListSelectorRemoved,
} from '../responsePlanListSelector/responsePlanListSelectorSlice'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />
})

const ResponsePlanSelectorDialog = ({
    open,
    onClose,
    setSelectedResponsePlan,
    responsePlans,
    selectedResponsePlan,
}: ResponsePlanSelectorDialogProps) => {
    const _responsePlanIsRemoved: boolean = useAppSelector(selectRemovedResponsePlan)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [selectedRP, setSelectedRP] = useState<number>(selectedResponsePlan)
    const [disableLoadButton, setDisableLoadButton] = useState<boolean>(true)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [responsePlanToDelete, setResponsePlanToDelete] = useState<IResponsePlan | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)
    const [feedbackMessage, setFeedbackMessage] = useState<string>('')

    const dispatch = useAppDispatch()

    useEffect(() => {
        setSelectedRP(selectedResponsePlan)
    }, [selectedResponsePlan])

    useEffect(() => {
        if (_responsePlanIsRemoved) {
            dispatch(loadManageLiveResponsePlan())
            setTimeout(() => {
                if (responsePlanToDelete && responsePlanToDelete.id === selectedRP) {
                    setDisableLoadButton(true)
                    setSelectedRP(selectedResponsePlan)
                }
                setDeleting(false)
                setOpenDeleteDialog(false)
                setResponsePlanToDelete(null)
                setFeedbackOpen(true)
                setFeedbackMessage(t('manageResponsePLan.responsePlanRemoved', { name: responsePlanToDelete?.name }))
                dispatch(setStaticResponsePlanListSelectorRemoved(false))
            }, 3000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_responsePlanIsRemoved])

    const handleSelectResponsePlan = () => {
        setSearchTerm('')
        if (selectedRP !== -1) {
            setSelectedResponsePlan(selectedRP)
        }
        onClose(false)
        setDisableLoadButton(true)
    }

    const handleNew = () => {
        dispatch(setResponsePlanSelected(true))
        dispatch(setResponsePlanCreating(true))
        dispatch(setOpenResponsePlanCreateDialog(true))
        onClose(false)
    }

    const handleClose = () => {
        setSearchTerm('')
        setSelectedRP(selectedResponsePlan)
        onClose(true)
        setDisableLoadButton(true)
    }

    const handleDeleteEvaluationDialog = (responsePlan: any) => {
        setOpenDeleteDialog(true)
        setResponsePlanToDelete(responsePlan)
    }

    const handleDeleteResponsePlan = () => {
        if (responsePlanToDelete) {
            setDeleting(true)
            dispatch(deleteResponsePlan({ responsePlan: responsePlanToDelete }))
        }
    }

    const handleDeleteDialogClose = () => {
        setResponsePlanToDelete(null)
        setOpenDeleteDialog(false)
    }

    const searchTermChange = (searchTerm: string) => {
        setSearchTerm(searchTerm.toLowerCase())
    }

    const checkBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisableLoadButton(false)
        setSelectedRP(Number(event.target.value))
    }

    const handleFeedBackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setFeedbackOpen(false)
        setFeedbackMessage('')
    }

    return (
        <CustomDialog
            handleClose={handleClose}
            title={t('manageResponsePLan.selectOneResponsePlan')}
            open={open}
            fullScreen={false}
            maxWidth={'xl'}
            header={
                <ResponsePlanDialogButtonCreate
                    onClick={() => handleNew()}
                    variant='text'
                    size='small'
                    startIcon={<Add />}>
                    <Typography variant='inherit'>{t('manageResponsePLan.newResponsePlan')}</Typography>
                </ResponsePlanDialogButtonCreate>
            }>
            <SearchTermWrapperEvaluationDialog>
                <SearchTermComponent
                    searchTermChange={searchTermChange}
                    iconColor='inherit'
                    startAdornment={true}
                    placeholder={t('manageResponsePLan.searchResponsePlanByIdOrName')}
                    disableUnderline={false}
                />
            </SearchTermWrapperEvaluationDialog>
            <TableContainer component={Container} sx={{ marginBottom: '30px', maxHeight: '50vh' }}>
                <Table aria-label='response plans table' stickyHeader key='response plans table'>
                    <TableHeadStyled>
                        <TableRow>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>{t('search.id')}</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>
                                    {t('manageResponsePLan.responsePlanName')}
                                </TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'></TableCellHeaderStyled>
                        </TableRow>
                    </TableHeadStyled>
                    <TableBody className='table__body'>
                        {responsePlans
                            .filter(rp => {
                                const { id, name } = rp

                                return id!.toString().includes(searchTerm) || name.toLowerCase().includes(searchTerm)
                            })
                            .sort((curr, next) => next.id! - curr.id!)
                            .map((rp, index) => {
                                const { id, name } = rp

                                return (
                                    <TableRowContent
                                        key={id}
                                        className={index % 2 === 0 ? 'table__row--even' : 'table__row--odd'}>
                                        <TableCellContentStyled align='left' key='id'>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={id === selectedRP}
                                                        onChange={checkBoxChange}
                                                        value={id}
                                                        color='primary'
                                                    />
                                                }
                                                label={id}
                                            />
                                        </TableCellContentStyled>
                                        <TableCellContentStyled align='left' key='name'>
                                            <Typography variant='body1'>{name || 'not set'}</Typography>
                                        </TableCellContentStyled>

                                        <TableCellContentStyled key='delete'>
                                            <Tooltip
                                                title={rp.in_use ? t('manageResponsePLan.responsePlanInUseAlert') : ''}>
                                                <span>
                                                    <IconButton
                                                        aria-label='delete'
                                                        disabled={rp.in_use}
                                                        color='primary'
                                                        onClick={() => handleDeleteEvaluationDialog(rp)}>
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCellContentStyled>
                                    </TableRowContent>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ResponsePlanDialogButtonsWrapper>
                <div style={{ display: 'flex', flexGrow: 1 }} />
                <ResponsePlanDialogButton onClick={handleClose} variant='text'>
                    <Typography variant='button' color='primary'>
                        {t('buttons.cancel')}
                    </Typography>
                </ResponsePlanDialogButton>
                <ResponsePlanDialogButton
                    onClick={() => handleSelectResponsePlan()}
                    variant='contained'
                    disabled={disableLoadButton}>
                    <Typography variant='button'>{t('buttons.load')}</Typography>
                </ResponsePlanDialogButton>
            </ResponsePlanDialogButtonsWrapper>
            <CustomDialog
                open={openDeleteDialog}
                title={t('manageResponsePLan.deleteResponsePlanTitle', { name: responsePlanToDelete?.name })}
                transition={Transition}
                handleClose={handleDeleteDialogClose}
                fullScreen={false}
                maxWidth={'sm'}>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                        {t('manageResponsePLan.deleteResponsePlanConfirmation', { name: responsePlanToDelete?.name })}
                        <br />
                        {t('manageResponsePLan.noTurningBack')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ResponsePlanDialogButtonsWrapper>
                        <ResponsePlanDialogButton onClick={handleDeleteDialogClose} variant='text'>
                            <Typography variant='button' color='primary'>
                                {t('buttons.cancel')}
                            </Typography>
                        </ResponsePlanDialogButton>
                        <ResponsePlanDialogButton
                            onClick={() => (deleting ? {} : handleDeleteResponsePlan())}
                            variant='contained'>
                            {deleting ? (
                                <Typography variant='button'>
                                    <PulseLoader size={8} margin={2} color='#FFF'></PulseLoader>
                                </Typography>
                            ) : (
                                <Typography variant='button'>{t('buttons.delete')}</Typography>
                            )}
                        </ResponsePlanDialogButton>
                    </ResponsePlanDialogButtonsWrapper>
                </DialogActions>
            </CustomDialog>
            <Snackbar
                open={feedbackOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={handleFeedBackClose}>
                <Alert onClose={handleClose} severity={'info'} sx={{ width: '100%' }}>
                    {feedbackMessage}
                </Alert>
            </Snackbar>
        </CustomDialog>
    )
}

export default ResponsePlanSelectorDialog
